import React, { useState, useEffect } from 'react'

const groups = [
   { id: 'gold-gf', }, 
   { id: 'gold-spot', }, 
   { id: 'fx-gss', }, 
   { id: 'index', }, 
   { id: 'cme', },
   { id: 'exchange', }
]

export default function Datafeed() {
   const [handleInterval, setHandleInterval] = useState()
   const [showModal, setShowModal] = useState(false)
   const [list, setList] = useState([])

   const [groupId, setGroupId] = useState('gold-gf')
   const [symbols, setSymbols] = useState([])

   function setSymbolsList() {
      fetch(`/datafeed/app/set/${groupId}`, {
         method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({symbols: list})
      }).catch(() => { console.log('Set Symbol Error') })
      setShowModal(false)
   }

   useEffect(() => {
      fetch(`/datafeed/app/get/${groupId}`)
      .then((response) => response.json())
      .then((data) => { setList(data?.symbols || []) })
      .catch(() => { console.log('Get Symbol List Error') })
   }, [groupId, showModal])

   useEffect(() => {
      clearInterval(handleInterval);
      let interval = setInterval(() => { 
         fetch(`/datafeed/app/feed/${groupId}`)
         .then((response) => response.json())
         .then((data) => { setSymbols(data) })
         .catch(() => { console.log('Data Feed Error'); })
      }, 1000)
      setHandleInterval(interval)
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [groupId])

   return (
      <div>
         { showModal && <SettingModal groupId={groupId} list={list} setList={setList} setSymbolsList={setSymbolsList} setShowModal={setShowModal} /> }
         <div className="flex">
            { groups.map(({id}) => <GroupItem key={id} id={id} setGroupId={setGroupId} />) }
            <button className="px-4 py-1 font-bold border ml-auto" onClick={() => { setShowModal(true) }}> Setting </button>
         </div> 
         <div>
            <HeadRow type={groupId} symbol="Symbol" last="Last" bid="Bid" ask="Ask" high="High" low="Low" changeprice="Change" changepercent="Percent" diff="Diff" />
            { symbols.map((data) => data && <FeedRow key={data.symbol} type={groupId} {...data} />)}
         </div>        
      </div>

   )
}

const SettingModal = ({ groupId, list, setList, setSymbolsList, setShowModal }) => {


   return (
      <div className={`fixed inset-0 bg-gray-800 bg-opacity-50 overflow-y-auto h-full w-full`}>
         <div className="flex justify-center items-center h-full">
            <div className="grid bg-white p-4 rounded-lg gap-4"> 
               <div className="flex justify-between">
                  <div>Setting Symbol : <span className='py-1 px-2 border rounded font-bold'>{`${groupId}`}</span></div>
                  <div className='cursor-pointer' onClick={() => { setShowModal(false) }}><i className="material-icons">clear</i></div>
               </div>
               <div className="grid grid-cols-2 gap-1">
                  <div className="">Symbol List</div>
                  <div>Compare With</div>
                  { list.map((item, i) => <div className="col-span-2 grid grid-cols-2 gap-2"> 
                        <div className="flex">
                           <div className="border py-1 px-2">
                              <input type="checkbox" checked={item.show} 
                                 onChange={(event) => { list[i].show = event.target.checked; setList([...list]) }} />                     
                           </div>
                           <div className="border py-1 px-2">
                              <input className="w-32" type="text" value={item.symbol} 
                                 onChange={(event) => { list[i].symbol = event.target.value; setList([...list]) }}/>
                           </div>
                           <button className="border p-1 px-2"
                              onClick={() => { list.splice(i, 1); setList([...list]) }}>
                              <i className="material-icons text-sm">clear</i>
                           </button>
                        </div>         
                        <div className="flex">
                           <input className="border py-1 px-2 w-44" type="text" value={item.compare} 
                              onChange={(event) => { list[i].compare = event.target.value; setList([...list]) }}/>
                           <button className="border p-1 px-2"
                              onClick={(event) => { list[i].compare = ''; setList([...list]) }}
                              ><i className="material-icons text-sm">backspace</i></button>
                        </div>                                         
                     </div>
                     )}
               </div>
               <div className="flex justify-between">
                  <button className="py-1 px-2 border rounded-lg bg-green-600 text-white flex items-center gap-1" 
                     onClick={() => { setList([...list, { symbol: '', show: false, compare: ''} ]) }}> 
                     <span className="material-icons text-lg">add_circle_outline</span> Add Symbol
                  </button>
                  <button className="py-1 px-2 border rounded-lg bg-blue-600 text-white flex items-center gap-1" 
                     onClick={() => { setSymbolsList() }}> 
                     <span className="material-icons text-lg">save</span> Save 
                  </button>
               </div>
            </div>
         </div>
      </div>
   )
} 

const HeadRow = ({ type, symbol, last, bid, ask, high, low, changeprice, changepercent, diff }) => {
   return ( 
      <div className="text-center bg-black text-white text-xl">
         { type === 'gold-gf' &&       
            <div className="grid grid-cols-12">
               <div className="col-span-3 border border-white py-1">{symbol}</div>
               <div className="col-span-4 border border-white py-1">{last}</div>
               <div className="col-span-3 border border-white py-1">{changeprice}</div>
               <div className="col-span-2 border border-white py-1">{diff}</div>
            </div>  
         }
         { type === 'gold-spot' &&       
            <div className="grid grid-cols-12">
               <div className="col-span-3 border border-white py-1">{symbol}</div>
               <div className="col-span-3 border border-white py-1">{bid}</div>
               <div className="col-span-3 border border-white py-1">{ask}</div>
               <div className="col-span-3 border border-white py-1">{changeprice}</div>
            </div>  
         }
         { type === 'fx-gss' &&       
            <div className="grid grid-cols-12">
               <div className="col-span-3 border border-white py-1">{symbol}</div>
               <div className="col-span-3 border border-white py-1">{last}</div>
               <div className="col-span-2 border border-white py-1">{high+'-'+low}</div>
               <div className="col-span-2 border border-white py-1">{changeprice}</div>
               <div className="col-span-2 border border-white py-1">{changepercent}</div>
            </div>  
         }
      </div>
   )
}

const FeedRow = ({ type, symbol, last, bid, ask, high,low, changeprice, changepercent, diff }) => {
   let color = (+changeprice > 0) ? 'lime' : (changeprice < 0) ? 'red' : 'white'

   return (
      <div>
         { type === 'gold-gf' &&        
            <div className="grid grid-cols-12 text-center bg-black text-white">
               <div className="grid col-span-3 border border-white text-yellow-500" style={{fontSize: '4.6vw'}} >
                  <div className="self-center">{symbol}</div>
               </div>
               <div className={`grid col-span-4 border border-white`} style={{fontSize: '6vw', color: color}} >
                  <div className="self-center">{last}</div>
               </div>
               <div className={`grid col-span-3 border border-white`} style={{fontSize: '4.6vw', color: color}} >
                  <div className="self-center">{changeprice}</div>
               </div>
               <div className="grid col-span-2 border border-white" style={{fontSize: '4.6vw'}} >
                  <div className="self-center">{diff}</div>
               </div>
            </div>  
         }
         { type === 'gold-spot' && 
            <div className="grid grid-cols-12 text-center bg-black text-white">
               <div className="grid col-span-3 border border-white text-yellow-500" style={{fontSize: '4vw'}} >
                  <div className="self-center">{symbol}</div>
               </div>
               <div className={`grid col-span-3 border border-white`} style={{fontSize: '5vw', color: color}} >
                  <div className="self-center">{bid}</div>
               </div>               
               <div className={`grid col-span-3 border border-white`} style={{fontSize: '5vw', color: color}} >
                  <div className="self-center">{ask}</div>
               </div>
               <div className={`grid col-span-3 border border-white`} style={{fontSize: '4vw', color: color}} >
                  <div className="self-center">{changeprice}</div>
               </div>
            </div>  
         }
         { (type === 'fx-gss' || type === 'index' || type === 'cme' || type === 'exchange') &&       
            <div className="grid grid-cols-12 text-center bg-black text-white">
               <div className="grid col-span-3 border border-white text-yellow-500" style={{fontSize: '4vw'}} >
                  <div className="self-center">{symbol}</div>
               </div>
               <div className={`grid col-span-3 border border-white`} style={{fontSize: '5vw', color: color}} >
                  <div className="self-center">{last}</div>
               </div>               
               <div className={`grid col-span-2 border border-white`} style={{fontSize: '2.4vw'}} >
                  <div className="self-center" style={{color: 'lime'}}>{high}</div>
                  <div className="self-center" style={{color: 'red'}}>{low}</div>
               </div>
               <div className={`grid col-span-2 border border-white`} style={{fontSize: '3vw', color: color}} >
                  <div className="self-center">{changeprice}</div>
               </div>
               <div className={`grid col-span-2 border border-white`} style={{fontSize: '3vw', color: color}} >
                  <div className="self-center">{changepercent}</div>
               </div>
            </div>  
         }
      </div>
   )
}

const GroupItem = ({id, setGroupId}) => {
   return (
      <button className="px-4 py-1 font-bold border" 
         onClick={() => { setGroupId(id) }}> {id} 
      </button>
   )
}