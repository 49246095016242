import { useState, useEffect, useContext  } from "react"
import { Config, Profile } from "../../Context"

const AccountList = ({ setShowAccountList, setShowAccountModal }) => {
   const [profile] = useContext(Profile)
   const [accounts, setAccounts] = useState([]) 

   useEffect(() => { 
      fetch(`/mtscapital/line/liff/accounts/user/${profile?.userId}`)
      .then((res) => res.json())
      .then((accounts) => { setAccounts(accounts) } ) 
   }, [profile])

   return (
      <div className="absolute border bg-white rounded-lg px-1 mt-1 right-0">
         { accounts.map(({account, approved}) => <AccountItem key={account} account={account} approved={approved}/>) }
         <AccountAddButton setShowAccountList={setShowAccountList} setShowAccountModal={setShowAccountModal}/>
      </div>
   )
}

const AccountItem = ({account, approved}) => {
   return (
      <button className="flex gap-1 items-center ml-auto px-2 py-1 text-sm">
         <i className={`material-icons text-sm text-${(approved)? 'green': 'red'}-700`}>radio_button_checked</i>
         <div>{account}</div>
      </button>
   )
}

const AccountAddButton = ({setShowAccountList, setShowAccountModal}) => {
   const [{language}] = useContext(Config)

   const handleClickShowAccountModal = () => { 
      setShowAccountModal(true)
      setShowAccountList(false)
   }

   return (
      <button className="flex gap-1 items-center mx-auto p-1 text-sm"
         onClick={handleClickShowAccountModal}> 
         <i className="material-icons">add_circle_outline</i> 
         {(language === 'en') ? 'New Account' : 'เพิ่มบัญชีซื้อขาย'}
      </button> 
   )
}



export default AccountList