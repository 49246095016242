import { useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import './datafeed.css';

const Datafeed2 = () => {
   const headers = ['Symbol', 'Last', 'High-Low', 'Change', 'Percent']

   const [handleInterval, setHandleInterval] = useState()
   const [groupId, setGroupId] = useState('gold-gf')
   const [datafeeds, setDatafeeds] = useState([])

   const [showSetting, setShowSetting] = useState(false)

   const getDataFeed = async () => { 
      const data = await fetch(`/datafeed/app/feed/${groupId}`).then((response) => response.json())
      setDatafeeds(data)
   }


   useEffect(() => { 
      clearInterval(handleInterval);
      const intervalId = setInterval(getDataFeed, 1000)
      setHandleInterval(intervalId)
   }, [groupId])

   const [list, setList] = useState([])
   const getList = async () => {
      const data = await fetch(`/datafeed/app/get/${groupId}`).then((response) => response.json())
      setList(data?.symbols)
      // setList(sample_list.symbols)
   } 
   useEffect(() => { getList() }, [groupId, showSetting])


   return (
      <div>
         { showSetting && <SettingModal groupId={groupId} list={list} setList={setList} setShowSetting={setShowSetting} />}
         <SwitchSymbolGroup groupId={groupId} setGroupId={setGroupId} setShowSetting={setShowSetting}/>
         <table className="table">
            <thead>{ headers.map((header) => <th className="header" key={uuidv4()}>{header}</th>) }</thead>
            <tbody>{ datafeeds.map((data) => <DataFeedRow data={data} />) }</tbody>   
         </table>       
      </div>

   )
}

export default Datafeed2

const SwitchSymbolGroup = ({ groupId, setGroupId, setShowSetting }) => {
   const groups = ['gold-gf', 'gold-spot', 'fx-gss', 'index', 'cme']
   const handleClickSetting = () => { setShowSetting((showSetting) => !showSetting) }
   return ( 
      <div className="switch-symbols"> 
         { groups.map((value) => <SwitchSymbolGroupButton value={value} groupId={groupId} setGroupId={setGroupId} />)}
         <button className="switch-setting" onClick={handleClickSetting}>Setting</button>
      </div>
   )
}

const SwitchSymbolGroupButton = ({ value, groupId, setGroupId }) => {
   const handleClickGroup = () => { setGroupId(value) }
   return <button className="switch-group" onClick={handleClickGroup} disabled={value === groupId}>{value}</button>
}


const DataFeedRow = ({ data }) => {
   const change_color = Number(data.changeprice) > 0 ? 'higher' : Number(data.changeprice) < 0 ? 'lower' : ''
   return (
      <tr className="row">
         <td className="symbol">{data.symbol}</td>
         <td className={`last ${change_color}`}>{data.last}</td>
         <td className="">
            <div className="high">{data.high}</div>
            <div className="low">{data.low}</div>
         </td>
         <td className={`change ${change_color}`}>{data.changeprice}</td>
         <td className={`percent ${change_color}`}>{data.changepercent}</td>
      </tr>
   )
}

const SettingModal = ({ groupId, list, setList, setSymbolsList, setShowSetting }) => {
   
   // 
   return (
      <div>
         <div className="modal-background" > 
            <div className="flex-background">
               
               <div className="modal">
                  <SettingModalHeader groupId={groupId} setShowSetting={setShowSetting}/> 
                  <SettingModalBody list={list} setList={setList}/> 
                  <SettingModalFooter groupId={groupId} list={list} setList={setList} setShowSetting={setShowSetting}/>
               </div>   
            </div>           
         </div>
      </div>
      
     


   )
}

const SettingModalHeader = ({ groupId, setShowSetting }) => {
   const handleClickCloseModal = () => { setShowSetting(false) }
   return (
      <div className="modal-header">
         <div>Setting Symbol - {groupId}</div>
         <button onClick={handleClickCloseModal}>X</button>
      </div>
   )
}

const SettingModalBody = ({ list, setList }) => {

   return (
      <div className="modal-body">
         <div className="input-header">
            <div className="input-title">Symbol List</div>
            <div className="input-title">Compare With</div>            
         </div>
         { list.map((item, i) =>  <SymbolSetting i={i} list={list} setList={setList}/>)}
      </div>
   )
}

const SettingModalFooter = ({ groupId, list, setList, setShowSetting }) => { 
   const handleClickAdd = async () => { setList([...list, { symbol: '', show: false, compare: ''} ]) }
   const handleClickSave = async () => { 
      await fetch(`/datafeed/app/set/${groupId}`, { 
         method: 'POST', 
         headers: { 'Content-Type': 'application/json' }, 
         body: JSON.stringify({symbols: list}) 
      })
      setShowSetting(false)
   }

   return (
      <div className="modal-footer">
         <button className="add-symbol" onClick={handleClickAdd}>Add Symbol</button>
         <button className="save-symbol" onClick={handleClickSave}>Save</button>
      </div>
   )
}


const SymbolSetting = ({ i, list, setList }) => { 
   const handleClickShow = () => { list[i].show = !list[i].show; setList([...list]); }
   const handleChangeSymbol = (event) => { list[i].symbol = event.target.value; setList([...list]); }
   const handleClickDelete = () => { list.splice(i, 1); setList([...list]); }
   
   const handleChangeCompare = (event) => { list[i].compare = event.target.value; setList([...list]); }
   const handleClickClear = () => { list[i].compare = ""; setList([...list]); }

   return (
      <div className="input-row">
         <div className="input-group left">
            <button className="show-symbol" onClick={handleClickShow}>{ list[i].show ? 'show' : 'hide' }</button>
            <input className="input-symbol" value={list[i]?.symbol} onChange={handleChangeSymbol} />
            <button className="delete-symbol" onClick={handleClickDelete}>x</button>
         </div>
         <div className="input-group right">
            <input className="input-compare" value={list[i].compare} onChange={handleChangeCompare}/>
            <button className="clear-compare" onClick={handleClickClear}>{'-'}</button>
         </div>
      </div>
   )
}

