import { useState, useEffect, useRef, useContext } from "react"
import { Config, Profile } from "../../Context"

const AccountModal = ({ setShowAccountModal }) => {
   const [config, setConfig] = useContext(Config)
   const modalCloseRef = useRef()
   const modalBackgroundRef = useRef()
   const handleClickExitAccountModal = (event) => { 
      if( event.target === modalCloseRef.current || event.target === modalBackgroundRef.current) setShowAccountModal(false)
   }
   

   const [data, setData] = useState({account: '', tel: '', idcard: ''}) 

   const [result, setResult] = useState('')
   const handleClickConfirmData = () => { 
      if(!data.account || !data.tel || !data.idcard) {
         setResult({ status: 'error', message: (config?.language === 'en')? 'Invalid Data': 'ข้อมูลไม่ถูกต้อง' })
         return;
      }
      fetch('/mtscapital/line/liff/account/check',{
         method: 'POST', 
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({...data, language: config?.language}) 
      })
      .then((response) => response.json())
      .then((result) => setResult(result))
      .catch()
   }
   // console.log(data);

   const [countdown, setCountdown] = useState(0)
   const [intervalId, setIntervalId] = useState(0)

   useEffect(() => {
      if(result.status === 'success' || countdown < 0) {
         clearInterval(intervalId)
         setTimeout(() => { setShowAccountModal(false) }, 3000) 
      }
   }, [result, countdown, intervalId, setShowAccountModal, setConfig])

   return (
      <div ref={modalBackgroundRef} className="fixed left-0 top-0 w-full h-screen bg-black/50 flex justify-center items-center z-50" >
         <div className="bg-white rounded-lg w-60 p-4 grid gap-2">
            <div className="flex justify-between mb-2">
               <div className="text-lg font-semibold">
                  { config?.language === 'en' ? 'Register Account' : 'ลงทะเบียนบัญชี' }
               </div>
               <button ref={modalCloseRef} className="material-icons" onClick={handleClickExitAccountModal}>close</button>
            </div>
            <div className="grid gap-1">
               <AccountInputItem setData={setData} name="account" label={config?.language==='en'?'Account':'เลขบัญชี'} />
               <AccountInputItem setData={setData} name="tel"     label={config?.language==='en'?'Tel':'เบอร์โทร'} />
               <AccountInputItem setData={setData} name="idcard"  label={config?.language==='en'?'IdCard':'เลขบัตรประชาชน'} />
            </div>
            <div className="grid gap-1">
               <button className="bg-blue-900 text-white font-semibold rounded py-1 mt-2 disabled:opacity-70" 
                  disabled={result?.mode === 'otp'} onClick={handleClickConfirmData}> 
                  { config?.language === 'en' ? 'Confirm Data' : 'ยืนยันข้อมูล' } 
               </button>
               <div className="flex justify-between text-sm">
                  <div>{ result?.mode === 'otp' ? `Ref: ${result?.ref || ''}` : ''}</div> 
                  <div className={(result?.status === 'error') ? 'text-red-600' : (result?.status === 'success') ? 'text-green-600' : '' }>{result?.message}</div>
               </div>
               { result?.mode === 'otp' && countdown <= 0 && <AccountSendOTP tel={data.tel} setResult={setResult} setCountdown={setCountdown} setIntervalId={setIntervalId}/> }
               { result?.mode === 'otp' && countdown > 0 && <AccountInputOTP result={result} setResult={setResult} data={data} countdown={countdown}/> }
            </div>
         </div>
      </div>
   )
}

const AccountInputOTP = ({result, setResult, data, countdown}) => {
   const [config] = useContext(Config)
   const [profile] = useContext(Profile)

   const placeholder = (result?.status === 'success') 
      ? (config?.language === 'en') ? result?.message_en : result?.message_th
      : (countdown > 0) ? (config?.language === 'en') ? `Input OTP (${countdown} sec)` : ` กรอก OTP (${countdown} วินาที)` : ''


   const handleChangeInputOTP = (event) => { 
      if(event.target.value.length === 6) {
         fetch('/mtscapital/line/liff/account/add/checkotp', { 
            method: 'POST', 
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
               language: config?.language, 
               userId: profile?.userId, 
               idcard: data?.idcard, 
               account: data?.account,
               tel: data?.tel,
               code: event.target.value, 
               ref: result.ref 
            }) 
         })
         .then((response) => response.json())
         .then((data) => { setResult(data) })
         .catch(() => { console.log('Check OTP Error'); })
         event.target.value = ''
      }
   }


   return (
      <input className={`w-full border text-center p-2`} disabled={result?.status === 'success'}
         placeholder={placeholder} 
         onChange={handleChangeInputOTP}/>
   )
}

const AccountSendOTP = ({tel, setResult, setCountdown, setIntervalId}) => { 
   const [profile] = useContext(Profile)
   const [{language}] = useContext(Config)


   const handleClickSendOTP = () => { 
      fetch('/mtscapital/line/liff/account/add/sendotp',{
         method: 'POST', 
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({ userId: profile?.userId, tel: tel, language: language}) 
      })
      .then((response) => response.json())
      .then((data) => { setResult(data) })
      .catch()
      setCountdown(60)
      setIntervalId(setInterval(() => { setCountdown((prev) => prev-1) }, 1000))
   }

   return (
      <button className="bg-orange-200 w-full py-1 font-semibold" onClick={handleClickSendOTP} > 
         { language === 'en' ? 'Send OTP' : 'ส่งรหัส OTP' } 
      </button>
   )
}

const AccountInputItem = ({label, name, placeholder, setData }) => {

   const handleChangeSetValue = (event) => { 
      setData((data) => ({...data, [name]: event.target.value }) )  
   }
   
   return (
      <div className="">
         <label className="absolute px-1 bg-white cursor-pointer text-gray-500 text-sm" htmlFor={name+'-input'} >{label}</label>
         <input type="text" id={name+'-input'} className="border mt-3 p-2 rounded w-full"
            placeholder={placeholder}
            onChange={handleChangeSetValue}/>
            
      </div>      
   )
}

export default AccountModal