import React from 'react'
import { useState, useEffect } from 'react'
import { Chart } from "react-google-charts"

const Dashbaord = () => {
   const [userStat, setUserStat] = useState({})
   const [messageStat, setMessageStat] = useState({})

   const getUserStat = async () => {
      let result = await fetch('/mtsplus/app/stat/users').then((response) => response.json())
      setUserStat(result)
   } 

   const getMessageStat = async () => {
      let result = await fetch('/mtsplus/app/stat/message').then((response) => response.json())
      setMessageStat(result)
   }  

   useEffect(() => {      
      getUserStat()
      getMessageStat()
   }, [])

   return (
      <div className="container my-3">
         <div className="text-xl font-bold">Dashboard</div>
         <div>
            <div className='font-bold'>User</div>
            <div className='grid grid-cols-3 gap-2 my-2'>
               <UserAll userStat={userStat} />
               <UserFollow userStat={userStat}/>
               <UserBlock userStat={userStat}/>
               <UserType userStat={userStat}/>
               <UserChart userStat={userStat}/>            
            </div>            
         </div>
         <div>
            <div className='font-bold'>Message</div>
            <div className='grid grid-cols-3 gap-2 my-2'>
               <MessageQuota messageStat={messageStat} />
               <div className='grid gap-2'>
                  <MessageBroadcast messageStat={messageStat} />
                  <MessageMulticast messageStat={messageStat} />
                  <MessagePush messageStat={messageStat} />               
               </div>
            < MessageChart messageStat={messageStat} />
            </div>
         </div>


      </div>      
   )
}

const UserAll = ({userStat}) => {
   return (
      <div className='border rounded shadow px-4 py-2 bg-blue-200'>
         <div className='flex justify-between text-blue-800'>
            <div className='font-bold'>Users</div>
            <div className='text-2xl font-bold'>{userStat.users}</div>
         </div>
      </div>
   )
}

const UserFollow = ({userStat}) => {
   return (
      <div className='border rounded shadow px-4 py-2 bg-green-200'>
         <div className='flex justify-between text-green-800'>
            <div className='font-bold'>Follow</div>
            <div className='text-2xl font-bold'>{userStat.follower}</div>
         </div>
      </div>
   )
}

const UserBlock = ({userStat}) => {
   return (
      <div className='border rounded shadow px-4 py-2 bg-red-200'>
         <div className='flex justify-between text-red-800'>
            <div className='font-bold'>Block</div>
            <div className='text-2xl font-bold'>{userStat.block}</div>
         </div>
      </div>
   )
}


const UserType = ({userStat}) => {
   const TypeItem = ({label, value}) => {
      return (
         <div className='flex justify-between gap-3'>
            <div className='text-sm'>{label}</div> 
            <div>{value}</div>               
         </div>
      )
   }

   return (
      <div className='border rounded shadow px-3 py-2 '>
         <div className='font-bold mb-2'>Type</div>
         <div className='pl-2'>
            <TypeItem label='Administrator' value={userStat?.type?.administrator} />
            <TypeItem label='Manager' value={userStat?.type?.manager} />
            <TypeItem label='Marketing' value={userStat?.type?.marketing} />
            <TypeItem label='Customer' value={userStat?.type?.customer} />
            <TypeItem label='Visitor' value={userStat?.type?.visitor} />
         </div>
      </div>
   )
}

const UserChart = ({userStat}) => {

   const data = [
      ['Role', 'Count'],
      ...Object.entries(userStat.type || {})
  ];

   return (
      <div className='border rounded shadow col-span-2'>
         <Chart
            chartType='PieChart'
            loader={ <div>Loading Data...</div> }
            data={data}
            width={'400px'}
            options={{ 
               chartArea: { left: 20, top: 40, width: '100%', height: '70%' },
               title: 'User Type', 
               pieHole: 0.4, 
               legend: { alignment: 'center', textStyle: { fontSize: 12 } }
            }}
         />
      </div>
   )
}


const MessageQuota = ({messageStat}) => {
   let usage = messageStat.totalUsage/messageStat.value*100 || 0
   return (
      <div className='col-span-full border rounded shadow px-4 py-2 bg-gray-100'>
         <div className='flex justify-between'>
            <div className='font-bold text-gray-800'> Quota</div>
            <div className='text-sm'>{messageStat.totalUsage} / {messageStat.value}</div>            
         </div>

         <div className='w-full border rounded-full bg-white my-2'>
            <div className='h-4 rounded-full bg-green-600 text-xs text-right pr-3 text-white font-bold' style={{width: `${usage}%`}}> {messageStat.totalUsage}</div>
         </div>
      </div>
   )
}

const MessageBroadcast = ({messageStat}) => {
   let sum = messageStat?.messages?.broadcast.reduce((prev, curr) => prev + curr) || 0
   return (
      <div className='border rounded shadow px-4 py-2 bg-blue-100'>
         <div className='flex justify-between text-blue-800'>
            <div className='font-bold'>Broadcast</div>
            <div className='text-2xl font-bold'>{sum}</div>
         </div>
      </div>
   )
}

const MessageMulticast = ({messageStat}) => {
   let sum = messageStat?.messages?.multicast.reduce((prev, curr) => prev + curr) || 0
   return (
      <div className='border rounded shadow px-4 py-2 bg-red-100'>
         <div className='flex justify-between text-red-800'>
            <div className='font-bold'>Multicast</div>
            <div className='text-2xl font-bold'>{sum}</div>
         </div>
      </div>
   )
}

const MessagePush = ({messageStat}) => {
   let sum = messageStat?.messages?.push.reduce((prev, curr) => prev + curr) || 0
   return (
      <div className='border rounded shadow px-4 py-2 bg-orange-100'>
         <div className='flex justify-between text-orange-800'>
            <div className='font-bold'>Push</div>
            <div className='text-2xl font-bold'>{sum}</div>
         </div>
      </div>
   )
}

const MessageChart = ({messageStat}) => {
   let date = messageStat?.messages?.date || []
   let broadcast = messageStat?.messages?.broadcast || []
   let multicast = messageStat?.messages?.multicast || []
   let push = messageStat?.messages?.push || []

   let arr = [0,0,0,0]
   for (let i = 0; i < date.length; i++) {
      arr[i] = [date[i], broadcast[i], multicast[i], push[i]]
   }

   let data = [
      ['date', 'broadcast', 'multicast', 'push'],
      ...arr.reverse()
   ]
 
   
   return (
      <div className='col-span-2 border rounded shadow'>
         <Chart
            chartType='LineChart'
            loader={ <div>Loading Data...</div> }
            data={data}
            width={'400px'}
            options={{ 
               chartArea: { left: 40, top: 40, width: '60%', height: '60%' },
               title: 'Messages', 
               legend: { alignment: 'center', textStyle: { fontSize: 12 } }
            }}
         />
      </div>
   )
}




export default Dashbaord
