import React, { useEffect, useState } from 'react'

const Richmenu = () => {
   const [update, setUpdate] = useState([])
   const [selectRichmenuId, setSelectRichmenuId] = useState()
   const [richmenuList, setRichmenuList] = useState([])  
   const [defaultRichmenuId, setDefaultRichmenuId] = useState('')

   useEffect(() => { 
      fetch('/mtsplus/app/richmenu/list')
      .then((response) => response.json())
      .then((result) => setRichmenuList(result))

      fetch('/mtsplus/app/richmenu/default')
      .then(response => response.json())
      .then(result => { setDefaultRichmenuId(result.richmenuId) }) 
   }, [update]) 

   return (
      <div className="container">
         <div className="text-xl font-bold my-3">Richmenu</div>

         <div className="grid grid-cols-3 gap-4">
            <div className="col-span-1">
               <RichmenuList richmenuList={richmenuList} defaultRichmenuId={defaultRichmenuId} selectRichmenuId={selectRichmenuId} setSelectRichmenuId={setSelectRichmenuId}/>
            </div>
            <div className="col-span-2 grid gap-4">
               { <RichmenuCreate setUpdate={setUpdate}/> }
               <RichmenuData selectRichmenuId={selectRichmenuId} defaultRichmenuId={defaultRichmenuId}/>
               <RichmenuUserList selectRichmenuId={selectRichmenuId} />
            </div>
         </div>
      </div>
   )
}

export default Richmenu

const RichmenuCreate = ({setUpdate}) => {
   const [showCreateRichmenu, setShowCreateRichmenu] = useState(false)
   const [fileImage, setFileImage] = useState()
   const [fileJSON, setFileJSON] = useState('')

   const handleClickShowCreateRichmneu = () => { setShowCreateRichmenu(!showCreateRichmenu)}

   const handleChangeImageFile = async (event) => {
      setFileImage({ 
         blob: event.target.files[0], 
         url: URL.createObjectURL(event.target?.files[0]), 
         name: event.target.files[0].name
      }) 
   }

   const handleChangeJSONFile = async (event) => { 
      setFileJSON({ 
         blob: event.target.files[0], 
         text: await event.target.files[0].text(),  
         name: event.target.files[0].name
      })
   }

   const handleChangeTextArea = async (event) => {
      setFileJSON({...fileJSON, text: event.target.value })
   }

   const handleClickCreateRichmenu = async (event) => {
      let formData = new FormData()
      formData.set('image', fileImage.blob, 'new_richmenu.jpg')
      formData.set('json', fileJSON.text)

      fetch(`/mtsplus/app/richmenu/create`, { 
         method: 'post', 
         body: formData 
      })
      .then((response) => response.json())
      .then((data) => { setUpdate([]) })
      .catch(() => { console.log('create richmenu error');})
   }

   return (
      <div className="grid gap-2">
         <button className="bg-blue-100" onClick={handleClickShowCreateRichmneu}>Create Richmenu</button>
         { showCreateRichmenu &&
            <div>
               <div className="grid">
                  <img className="w-full" src={fileImage?.url} alt="upload" />
                  <input className="" type="file" accept="image/jpg,image/png" onChange={handleChangeImageFile} />
               </div>
               
               <div className="grid">
                  <textarea className="border" rows="10" value={fileJSON.text} onChange={handleChangeTextArea} /> 
                  <input className="" type="file" accept=".json" onChange={handleChangeJSONFile} />
               </div>

               <button className="bg-red-100 py-1" onClick={handleClickCreateRichmenu}> Create Richmenu </button>
            </div>   
         }
      </div>
   )
}

const RichmenuList = ({richmenuList, defaultRichmenuId, selectRichmenuId, setSelectRichmenuId}) => {
   
   return(
      <ul className="grid">
         { richmenuList.map(({richMenuId, name}) => 
            <RichmenuItem key={richMenuId} 
               richmenuId={richMenuId} 
               richmenuName={name}
               defaultRichmenuId={defaultRichmenuId} 
               selectRichmenuId={selectRichmenuId}
               setSelectRichmenuId={setSelectRichmenuId}
               />
            ) 
         }
      </ul>
   )
}

const RichmenuItem = ({ richmenuId, richmenuName, defaultRichmenuId, selectRichmenuId, setSelectRichmenuId }) => {
   let selected = selectRichmenuId === richmenuId ? 'bg-blue-100' : ''

   return(
      <button className={`p-2 border text-left ${selected}`}
         onClick={() => { setSelectRichmenuId(richmenuId) }} >
         {richmenuName}
         { richmenuId === defaultRichmenuId && 
            <span className="mx-2 px-1 text-xs border rounded-full bg-orange-200">default</span>
         }
      </button>
   )
}


const RichmenuData = ({ selectRichmenuId, defaultRichmenuId }) => {
   const [richmenuImageUrl, setRichmenuImageUrl] = useState('')

   useEffect(() => { 
      selectRichmenuId && setRichmenuImageUrl(`/file/temp/richmenu/${selectRichmenuId}.png`)       
   }, [selectRichmenuId])

   const handleErrorRichmenuImage = () => {
      selectRichmenuId && 
      fetch(`/mtsplus/app/richmenu/image/${selectRichmenuId}`)
      .then(response => response.json())
      .then(result => { setRichmenuImageUrl(result.imageUrl) })  
   }

   const handleClickSetDefault = () => {
      fetch(`/mtsplus/app/richmenu/setdefault/${selectRichmenuId}`, { method: 'put' })
   }

   const handleClickCancelDefault = () => {
      fetch(`/mtsplus/app/richmenu/canceldefault`, { method: 'put' })
   }

   const handleDoubleClickDelete = () => {
      fetch(`/mtsplus/app/richmenu/delete/${selectRichmenuId}`, { method: 'delete' })
   }


   return(
      <div className="">
         <div className="border p-2 mb-2 max-w-lg">
            <img className="w-full" src={richmenuImageUrl} alt="richmenu" 
               onError={handleErrorRichmenuImage} />
         </div>
         <div className="grid gap-2">
            <div className=""> Id : {selectRichmenuId}</div>
            <div className="grid gap-2 grid-cols-2">
               { selectRichmenuId === defaultRichmenuId 
                  ? <button className="bg-yellow-200 py-1" onClick={handleClickCancelDefault}> Cancel Default </button> 
                  : <button className="bg-orange-200 py-1" onClick={handleClickSetDefault}> Set Default </button>
               }    
               <button className="bg-red-200 py-1" onDoubleClick={handleDoubleClickDelete}> Delete Richmenu </button>           
            </div>
         </div>

      </div>
   )
}


const RichmenuUserList = ({ selectRichmenuId }) => {
   const [richmenuUsers, setRichmenuUsers] = useState([])
   const [users, setUsers] = useState([])
   const [userId, setUserId] = useState('')
   const [update, setUpdateUserList] = useState([])

   useEffect(() => {
      selectRichmenuId && 
      fetch(`/mtsplus/app/richmenu/users/${selectRichmenuId}`)
      .then(response => response.json()).then(result => setRichmenuUsers(result))  
   }, [selectRichmenuId, update])

   useEffect(() => {
      fetch('/mtsplus/app/users')
      .then(response => response.json()).then(result => setUsers(result))      
   }, [])

   const handleClickLinkRichmenu = () => {
      fetch(`/mtsplus/app/richmenu/link/${userId}/${selectRichmenuId}`, { method: 'put' })
      .then(() => { setUpdateUserList([]); setUserId(''); })
   }

   return (
      <div className="grid gap-2">
         <div className="flex justify-between">
            <div>User List</div>
            <div className="flex gap-2">
               <button className="px-2 bg-green-200" onClick={handleClickLinkRichmenu} >Link</button> 
               <div className="">
                  <input className="border px-2" list="usersList" 
                     value={userId} onChange={(event) => { setUserId(event.target.value)}} />
                  <datalist id="usersList"> 
                     { users.map((user) => {
                           let userId = user?.line?.profile?.userId
                           let dispalyName = user?.line?.profile?.displayName
                           return (<option key={`richmenu-users-${userId}`} value={userId} >{dispalyName}</option>)
                        })
                     }                     
                  </datalist>

               </div>
            </div>
         </div>
         
         <div className="grid gap-1">
            { richmenuUsers.map((user) => <RichmenuUserItem user={user} setUpdateUserList={setUpdateUserList} />) }
         </div>
      </div>
   )
}

const RichmenuUserItem = ({user, setUpdateUserList}) => {

   const handleClickUnlink = () => {
      fetch(`/mtsplus/app/richmenu/unlink/${user.line.profile.userId}`, { method: 'put' })
      .then(() => { setUpdateUserList([]) })
   }

   return (
      <div className="flex justify-between border">
         <div className="px-2">{user.line.profile.displayName}</div>
         <button className="bg-red-300 px-2" onClick={handleClickUnlink}> Unlink </button>
      </div>
      
   )
}
