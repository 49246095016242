import React, { useEffect, useState } from 'react'

const Alerts = () => {
   const [search, setSearch] = useState('')
   const [alerts, setAlerts] = useState([])
   const [users, setUsers] = useState([])
   const [alertId, setAlertId] = useState('')
   const [select, setSelect] = useState()
   const [update, setUpdate] = useState()

   useEffect(() => {
      fetch(`/mtsplus/app/alerts`)
      .then((res) => res.json())
      .then((data) => { setAlerts(data) })

      fetch(`/mtsplus/app/users`)
      .then((res) => res.json())
      .then((data) => { setUsers(data) })
   }, [])

   useEffect(() => {
      alertId && fetch(`/mtsplus/app/alert/${alertId}`)
      .then((res) => res.json())
      .then((data) => { setSelect(data) })
   }, [alertId, update])


   return(
      <div className="container grid ">
         <div className="pt-3 px-3">
            <div className="text-xl font-bold">Alerts</div>
         </div>
         <div className="grid grid-cols-4 gap-4">
            <div className="">
               <input className="border px-2 py-1 w-full mb-2" placeholder="Search.."
                  value={search} onChange={(event) => { setSearch(event.target.value) }} />
               <div className="">
                  { alerts.map((item) => <AlertItem item={item} alertId={alertId} setAlertId={setAlertId} />)}
               </div>
            </div>
            <div className="col-span-3">
               <div className="grid gap-4">
                  <AlertData {...select} />
                  <AlertUsers alertId={alertId} select={select} users={users} setUpdate={setUpdate}/>
               </div>
            </div>
         </div>
      </div>
   )
}
export default Alerts

const AlertItem = ({ item, alertId, setAlertId }) => {
   return (
      <div className={`border px-3 py-2 cursor-pointer ${alertId === item.alertId ? 'bg-blue-100':''}`}
         onClick={() => { setAlertId(item.alertId) }}>
         { item.alertId }
      </div>
   )
}

const AlertData = ({ alertId, name, description }) => {
   return (
      <div className="grid gap-1">
         <div>Alert Data</div>
         <AlertDataItem label="AlertId" value={alertId} />
         <AlertDataItem label="Name" value={name} />
         <AlertDataItem label="Description" value={description} />
      </div>
   )
}

const AlertDataItem = ({ label, value }) => {
   return (
      <div className="flex text-sm">
         <div className="border px-2 py-1 bg-gray-200">{label}</div>
         <input className="border px-2 py-1 flex-grow" value={value} />
      </div>
   )
}

const AlertUsers = ({ alertId, select, users, setUpdate }) => {
   const [userList, setUserList] = useState([])
   const [addUser, setAddUser] = useState('')

   useEffect(() => {
      let list = select?.users?.map((item) => {
         let user = users?.find((u) => u?.line?.profile?.userId === item?.userId)       
         return { ...item, active: user?.active, role: user?.role, displayName: user?.line?.profile?.displayName }
      })
      setUserList(list || [])  
   }, [select, users])
   
   const addAlertUser = (userId) => {
      fetch(`/mtsplus/app/alert/${alertId}/push`, {
         method: 'PUT', 
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({ userId, language: 'th' })
      }).then(() => { 
         setAddUser('') 
         setUpdate(new Date())
      })
   }


   return (
      <div className="grid gap-2">
         <div className="flex justify-between">
            <div>Alert Users </div>
            <div className="flex gap-2">
               <input className="border " list="all-users" 
                  value={addUser} onChange={(event) => { setAddUser(event.target.value) }}/>
               <datalist id="all-users">
                  { users.map((user) => <option value={user?.line?.profile?.userId}>{user?.line?.profile?.displayName}</option> )}
               </datalist>
               <button className="border border-green-400 py-1 px-2 text-sm text-green-400 flex gap-1 items-center"
                  onClick={() => { addAlertUser(addUser) }} >
                  <i className="material-icons-outlined text-sm">add_circle_outline</i><div>User</div>
               </button>               
            </div>

         </div>
         
         <div className="grid gap-1">
            { userList.map((item) => <AlertUserItem key={alertId+'-'+item.userId} alertId={alertId} item={item} setUpdate={setUpdate}/>) }
         </div>
         
      </div>
   )
}


const AlertUserItem = ({ alertId, item, setUpdate }) => {
   const [value, setValue] = useState()
   useEffect(() => { setValue(item) }, [item])
   //console.log(value);

   const updateValue = (data) => {      
      console.log('update value');
      setValue({ ...data });   
      fetch(`/mtsplus/app/alert/${alertId}/set`, {
         method: 'PUT', 
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({ 
            userId: data.userId, 
            value: { 
               userId: data.userId, 
               enable: data.enable,
               language: data.language,
               expire: data.expire
            }
         })
      })
   }

   const removeValue = (userId) => {
      fetch(`/mtsplus/app/alert/${alertId}/pull`, { 
         method: 'PUT', 
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({ userId })
      })
      setUpdate(new Date())
   }

   return (
      <div className="flex justify-between text-sm">
         <div className="border px-2 py-1 bg-gray-200">{value?.role}</div>
         <div className="border px-2 py-1 flex-grow">{value?.displayName}</div>
         <button className={`border px-2 py-1 ${(value?.enable)? 'border-green-500 text-green-500': 'border-red-500 text-red-500'} `}
            onClick={() => { updateValue({ ...value, enable: !value?.enable}) }} >
            {value?.enable ? 'Enable': 'Disable'}
         </button>
         <button className="border px-2 py-1 " 
            onClick={() => { updateValue({ ...value, language: (value?.language === 'th') ? 'en' : 'th' }) }}>
            {value?.language?.toUpperCase()}
         </button>
         <input type="date" className="border px-2 py-1 w-36" value={value?.expire} 
            onChange={(event) => { updateValue({ ...value, expire: event.target.value }) }}/>
         <button className="border px-2 py-1" onDoubleClick={() => { removeValue(value.userId) }}>
            <i className="material-icons-outlined text-sm">close</i>
         </button>
      </div>
   )
}