import jwt_decode from "jwt-decode";

import { useState } from "react"
import RedeemPage from './Redeem/Index.jsx'

const MTSCapital = ({token, setToken}) => {
   const [page, setPage] = useState('')
   let login = jwt_decode(token);


   return (
      <div>
         <NavigatorBar setPage={setPage} setToken={setToken} login={login}/>
         <div className="container mx-auto">
            { page === 'redeem' && <RedeemPage login={login}/>}
         </div>
      </div>
   )
}

export default MTSCapital

const NavigatorBar = ({setPage, login, setToken }) => {
   const [showLoginMenu, setShowLoginMenu] = useState(false)

   const handleClickPageLiffRedeem = () => { setPage('redeem') }
   const handleClickLoginMenu = () => { setShowLoginMenu(!showLoginMenu) }
   const handleClickChangePassword = () => {}
   const handleClickLogout = () => { setToken('') }

   return(
      <div className="flex justify-between gap-4 p-4 bg-blue-900 text-white">
         <div > MTS Capital Line Manager</div>
         <div>
            <button className="flex items-center gap-1 hover:font-bold" 
               onClick={handleClickPageLiffRedeem}>
               <i className="material-icons">redeem</i> 
               <p>Redeem</p>
            </button>
         </div>
         <div className="relative">
            <button className="flex gap-1 items-baseline hover:font-bold" 
               onClick={handleClickLoginMenu}>
               <div className="text-xs">{login.role}</div>
               <div>{login.name}</div>
            </button> 
            { showLoginMenu &&
               <div className="absolute top-8 right-0 bg-gray-100 p-2 w-36 border-2">
                  { login.type === 'username' &&
                     <button className="text-black text-sm w-full text-right"
                        onClick={handleClickChangePassword}>
                        Change Password
                     </button>
                  } 
                  <button className="text-black text-sm w-full text-right"
                     onClick={handleClickLogout} >
                     Logout
                  </button>
               </div>            
            }
           
         </div>


      </div>      
   )
}





// const RedeemItems = () => {
//    const [items, setItems] = useState([])
//    const [update, setUpdate] = useState()

//    useEffect(() => { 
//       fetch('/mtscapital/app/liff-redeem/items')
//       .then((response) => response.json())
//       .then((data) => { setItems(data.items) })
//       .catch(() => { console.log('Error Get Redeem Items') })
//    }, [update])

//    return(
//       <div className="grid gap-2">
//          <div className="flex justify-between">
//             <div>Redeem Item</div>
//             <button className="bg-gray-100 px-4 py-1">New Items</button>
//          </div>
//          <div className="grid gap-2 lg:grid-cols-2 xl:grid-cols-3 w-full">
//             { items.map((item) => <RedeemItem key={'redeem-item-'+item.id} item={item} setUpdate={setUpdate}/>)}
//          </div>
//       </div>
//    )
// }

// const RedeemItem = ({ item, setUpdate }) => {
//    const formRef = useRef()
//    const InputRef = useRef()

//    const [editable, setEditable] = useState()
//    const [inputData, setInputData] = useState({ pictureUrl: item.pictureUrl, name: item.name, value: item.value, file: null })

//    const handleClickEditable = () => { setEditable(true) }
//    const handleClickSave = (event) => { 
//       fetch('/mtscapital/app/liff-redeem/item/update', { 
//          method: 'POST',
//          body: new FormData(formRef.current)
//       })
//       .then((response) => response.json())
//       .then((data) => { setUpdate([]) })
//       .catch(() => { console.log('Update Data Error') })
//       setEditable(false) 
//       formRef.current.reset()
//    }
//    const handleChangeNameInput = (event) => { setInputData((inputData) => ({...inputData, name: event.target.value })) }
//    const handleChangeValueInput = (event) => { setInputData((inputData) => ({...inputData, value: event.target.value })) }
   
//    const handleClickFileInput = () => { editable && InputRef.current.click() } 
//    const handleChangeFileInput = (event) => { setInputData((inputData) => ({...inputData, pictureUrl: URL.createObjectURL(event.target.files[0]) })) } 

//    return (
//       <form ref={formRef} className="flex gap-2" onSubmit={(event) => { event.preventDefault() }}>
//          <img className="w-32 border" src={inputData.pictureUrl} alt="item" onClick={handleClickFileInput}/>
//          <input ref={InputRef} className="hidden" type="file" name="imageFile" onChange={handleChangeFileInput} />
//          <div className="grid gap-1 w-full auto-rows-min">
//             <div className="flex justify-between">
//                {item.id} <input type="text" className="border w-full px-1 ml-1 hidden" name="id" value={item.id} readOnly={true} />
//                { editable
//                   ?  <button className="material-icons text-sm p-1" onClick={handleClickSave}>save</button>
//                   :  <button className="material-icons text-sm p-1" onClick={handleClickEditable}>edit</button>
//                }
               
//             </div>
//             <div className="flex gap-1"> 
//                <label className="w-12">name</label> 
//                { editable 
//                   ?  <input type="text" className="border w-full px-1 ml-1" 
//                         name="name" value={inputData.name} onChange={handleChangeNameInput} /> 
//                   :  item.name 
//                } 
//             </div>
//             <div className="flex gap-1"> 
//                <label className="w-12">value</label> 
//                { editable 
//                   ?  <input type="text" className="border w-full px-1 ml-1" 
//                         name="value" value={inputData.value} onChange={handleChangeValueInput} /> 
//                   :  item.value 
//                } 
//             </div>                  
//          </div> 
//       </form>
//    )
// }


// const UserPoint = () => {

//    return (
//       <div className="grid gap-2">
//          <UploadPoint />
//          <RecentDataPoint />
//       </div>
//    )
// }

// const UploadPoint = () => {
//    const [showForm, setShowForm] = useState(false)
//    return (
//       <div className="p-2 border">
//          <div className="flex justify-between my-1">
//             Upload Redeem Point File
//             <div onClick={() => { setShowForm(!showForm) }}>Expand</div>
//          </div>
//          { showForm && 
//             <form className="grid gap-1">
//                <input className="w-full border" type="file" />
//                <button className="p-1 bg-blue-200">Upload</button>            
//             </form>
//          }
//       </div>      
//    )
// }

// const RecentDataPoint = () => {
//    const [update, setUpdate] = useState([])
//    const [recentData, setRecentData] = useState([])

//    useEffect(() => { 
      
//       setRecentData([])
//    }, [update])

//    const handleClickRefresh = () => { setUpdate([]) }

//    return (
//       <div>
//          <div className="flex justify-between items-center">
//             <div>Recent Data Point</div>
//             <button className="text-xs" onClick={handleClickRefresh}> Refresh </button>
//          </div>
         
//          <div className="text-sm">
//             <RecentPointItem item={{datetime: 'Date', account: 'Account', point: 'Point'}}/>
//             { recentData.map((item) => <RecentPointItem item={item}/>)}
//          </div>
//       </div>
//    )
// }

// const RecentPointItem = ({item}) => {
//    return (
//       <div className="flex">
//          <div className="w-20">{item.datetime}</div>
//          <div className="flex-1">{item.account}</div>
//          <div className="w-20">{item.point}</div>
//       </div>      
//    )
// }