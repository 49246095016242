import { useContext, useState, useEffect } from "react"

import * as htmlToImage from 'html-to-image'
import QRCode from 'qrcode'

import { Config } from "../../Context"


const Receipt = ({ receiptRef, transactionId }) => {
   const [config] = useContext(Config)

   const [transaction, setTransaction] = useState(null)
   const [QRCodeUrl, setQRCodeUrl] = useState('')

   useEffect(() => {
      let url = `https://line.mtsbotic.com/mtscapital/line/liff/receipt/${transactionId}`
      QRCode.toDataURL(url).then(qr => { setQRCodeUrl(qr) })

      fetch(`/mtscapital/line/liff/transaction/redeem/receipt/${transactionId}`)
      .then((response) => response.json())
      .then((data) => { setTransaction(data) })
      .catch()
   }, [transactionId])

   useEffect(() => { 
      if(transactionId && transaction && receiptRef.current) {
         console.log(transactionId);
         htmlToImage.toBlob(receiptRef.current)
         .then((blob) => { 
            let filename = `${transactionId}.png`
            let formData = new FormData();
            formData.append('imageFile', blob, filename);
            formData.append('transactionId', transactionId);
            fetch('/mtscapital/line/liff/transaction/redeem/receipt/upload', { method: 'post', body: formData })
         })
      }
   }, [transactionId, transaction, receiptRef])

   return (
      <div ref={receiptRef} className="relative bg-white border shadow mx-auto rounded-lg" style={{ width: 300 }}>
         <div className="px-4 py-2">
            <div className="flex justify-between items-baseline">
               <div className='font-bold'>{ config.language === 'en' ? 'Redeem Complete' : 'แลกคะแนนสำเร็จ'}</div>
               <div className="text-xs"> {new Date(transaction?.datetime).toLocaleDateString('th-TH')} {new Date(transaction?.datetime).toLocaleTimeString('th-TH')}</div>            
            </div>
         </div>
         <hr className="" />
         <div className="px-4 py-2">
            <div className="flex gap-4">
               <div className="flex-1 grid gap-1 my-2"> 
                  { transaction?.items?.map((item) => <ReceiptItem key={'receipt-'+item.id} item={item}/> ) }
                  <div className="text-right font-bold">
                     { config.language === 'en' ? 'Summary' : 'ยอดรวม' } : { transaction?.value*-1 || ''}
                  </div>
               </div> 
            </div>      
         </div>
         <hr className="my-2" /> 
         <div className='flex gap-2 py-2 px-4'>
            <div className="text-right self-end py-1">
               <div className="text-sm">TransactionId </div>
               <div className="text-xs">{transactionId}</div>                   
            </div>
            <img className="mx-auto border w-24" src={QRCodeUrl} alt="qrcode" /> 
         </div>
         <div className="text-xs">Line: MTSCapitals - www.mtscapitals.com</div>
      </div>
   )
}

const ReceiptItem = ({item}) => {
   return (
      <div className="flex justify-between items-center">
         <div className="text-xs">{item.name} x {item.volume}</div>
         <div className="text-sm"> {item.volume*item.value} </div>
      </div>
   )
}

export default Receipt