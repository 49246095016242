import { useEffect, useState } from "react"

const Users = () => {
   const [refresh, setRefresh] = useState(new Date())

   const [usersId, setUsersId] = useState([])
   const [accounts, setAccounts] = useState([])

   console.log(usersId);
   useEffect(() => { 
      fetch('/mtscapital/app/redeem/accounts/approved')
      .then(res => res.json())
      .then(result => {
         setAccounts(result.accounts)
         setUsersId([...new Set(result.accounts.map(account => account.userId))])
      })
      .catch((error) => console.log(error))
   }, [refresh])

   return (
      <div className="border p-4 w-full">
         <div className="font-bold text-xl">Users</div>
         <div className="my-2">
            <div className="flex"> 
               <div className="w-48">Line Name</div>
               <div className="w-32">Account</div>
               <div className="w-32 text-right">Point</div>
            </div>
            { usersId.map((userId) => <UserItem key={userId} userId={userId} accounts={accounts}/>) }
         </div>
      </div>
   )
}

export default Users


const UserItem = ({ userId, accounts }) => {
   const [showAccount, setShowAccount] = useState(false)

   const [user, setUser] = useState({})
   const [account, setAccount] = useState([])
   const [transactions, setTransactions] = useState([])
   useEffect(() => { 
      console.log(userId);
      fetch(`/mtscapital/app/redeem/transactions/user/${userId}`)
      .then(res => res.json()).then(result => setTransactions(result.transactions))
      .catch((error) => console.log(error))

      if(accounts.length > 0) {
         setAccount(accounts.filter((account) => account.userId === userId))

         fetch(`/mtscapital/app/redeem/user/${userId}`)
         .then(res => res.json()).then(result => setUser(result.user))
         .catch((error) => console.log(error)) 
      }      
   }, [userId, accounts])




   return (
      <div className="flex py-1 border-t"> 
         <div className="w-48 text-sm">{user?.line?.profile?.displayName}</div>   
         <button className="text-left text-sm" onClick={() => { setShowAccount(!showAccount) }}>
            { !showAccount 
               ?  <div className="flex">
                     <div className="truncate w-32">{account.map((acc) => acc.account).join(',')}</div>
                     <div className="w-32 text-right">{ Number(transactions.reduce((prev, curr) => prev+Number(curr.value), 0)).toLocaleString('th-TH') }</div>
                  </div>
               :  <div> 
                     <div className="flex">
                        <div className="truncate w-32">by user</div> 
                        <div className="w-32 text-right">{ Number(transactions.reduce((prev, curr) => (userId === curr.userId && prev+Number(curr.value)) || prev, 0)).toLocaleString('th-TH') }</div>                        
                     </div>
                     {  account.map((acc) => 
                           <div className="flex">
                              <div className="truncate w-32">{acc.account}</div>
                              <div className="w-32 text-right">{ Number(transactions.reduce((prev, curr) => (acc.account === curr.account && prev+Number(curr.value)) || prev, 0)).toLocaleString('th-TH') } </div>
                           </div>
                        )
                     }
                  </div>
            }
         </button>
      </div>
   )
}