
import LineProfile from "./LineProfile"
import Language from "./Language"

import MTSGoldLogo from "./Images/mtsgold-logo.png"

const Header = ({ setPage }) => {

   return (
      <div className="fixed top-0 w-full z-10">
         <div className="flex justify-between p-2 items-center">
            <div><img src={MTSGoldLogo} className="w-24" alt="logo"/></div>
            <div className="flex gap-2 items-center">
               <LineProfile setPage={setPage}/>
               <Language />               
            </div>
         </div>         
      </div>
   )
}

export default Header