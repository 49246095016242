import React, { useState } from 'react';
import { GoogleLogin } from 'react-google-login';
const googleClientId = '442617960796-3v7cpeglo0u2atum4jffeb2olmc1fuv1.apps.googleusercontent.com'
             
const Login = ({ setToken }) => {
   const [signup, setSignup] = useState(false)

   const [username, setUsername] = useState('')
   const [password, setPassword] = useState('')
   const [confirmPassword, setConfirmPassword] = useState('')
   const [email, setEmail] = useState('')
   const [result, setResult] = useState('')

   const handleSubmitSignIn = () => {
      fetch('/auth/app/signin/username', {
         method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({ username, password })
      })
      .then((response) => response.json())
      .then((data) => { setToken(data.token); })
   }

   const handleSubmitSignUp = () => {
      let test = password === confirmPassword && /((?=.*\d)(?=.*[a-zA-Z])).{6,}/.test(password)
      if(!test) { setResult('password invalid'); return }

      fetch('/auth/app/signup/username', {
         method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({ username, password, email })
      })
      .then((response) => response.json())
      .then((data) => { setSignup(false); setResult(data.result); })
   }  
   
   const handleSubmitGoogleSignIn = (googleId, email) => {
      fetch('/auth/app/signin/google', {
         method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({googleId, email})
      })
      .then(response => response.json())
      .then(data => { setToken(data.token) }) 
   }


   return (
      <div className="grid justify-center h-screen items-center">         
         <div className="grid gap-2 w-96 border-2 p-4">
            <div className="flex justify-between">
               <div className="text-lg">MTS Line Official Manager</div>
               <button className="text-xs text-gray-400"
                  onClick={() => { setSignup(!signup) }}>Signup</button>               
            </div>
            <form className="grid gap-2" onSubmit={(event) => { event.preventDefault() }}>
               <input className="border-gray-300 border p-2 " placeholder="username" type="text" autoComplete="username"
                  value={username} onChange={(event) => { setUsername(event.target.value) }} />
               { signup && <input className="border-gray-300 border p-2 " placeholder="email" type="email" autoComplete="email"
                  value={email} onChange={(event) => { setEmail(event.target.value) }} /> }
               <input className="border-gray-300 border p-2 " placeholder="password" type="password" autoComplete="current-password"
                  value={password} onChange={(event) => { setPassword(event.target.value) }} />
               { signup && <input className="border-gray-300 border p-2 " placeholder="confirm password" type="password" autoComplete="current-password"
                  value={confirmPassword} onChange={(event) => { setConfirmPassword(event.target.value) }} /> }               
            </form>


            { signup 
               ? <button className="bg-blue-900 text-white p-2 " onClick={handleSubmitSignUp}> SignUp </button>
               : <button className="bg-blue-900 text-white p-2 " onClick={handleSubmitSignIn}> SignIn </button>
            }

            { !signup && 
               <div className="grid text-center">
                  <div> --or-- </div>
                  <GoogleLogin
                     clientId={googleClientId} 
                     onSuccess={({ profileObj }) => { 
                        console.log(profileObj);
                        handleSubmitGoogleSignIn(profileObj.googleId, profileObj.email) 
                     }} 
                     onFailure={(error) => { console.log(error) }}                
                     cookiePolicy={'single_host_origin'} 
                  />                  
               </div>

            }
            <div> {result} </div> 
         </div>
      </div>
   )
}

// function SubmitUserLogin(username, password, setResult, setToken) {
//    fetch('/auth/app/signin/username', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({username, password})
//    })
//    .then(response => response.json())
//    .then(data => { 
//       console.log(data);
//       setToken(data.token); 
//       //setResult(data.result);  
//    }) 
// }

// function submitGoogleLogin(googleId, email, setResult, setToken) {
//    fetch('/api/v3/login/google', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({googleId, email})
//    })
//    .then(response => response.json())
//    .then(data => { 
//       setToken(data.token); 
//       setResult(data.result); 
//    }) 
// }

export default Login

