


const Report = () => {
   return(
      <div>
         <ReportAccount />
         <div>Point</div>
         <ReportTransactions />
         <span class="material-icons">picture_as_pdf</span>
         <span class="material-icons">table_view</span>
      </div>
   )
}

export default Report


const ReportAccount = () => {
   return (
      <div>
         <div>Account</div>

      </div>
   )
}

const ReportTransactions = () => {
   const table_header = ['No.', 'SYSTEM', 'MARKETING', 'ACCOUNT', 'NAME', 'REDEEM_POINT', 'VALUE']
   const table_body = ['No.', 'SYSTEM', 'MARKETING', 'ACCOUNT', 'NAME', 'REDEEM_POINT', 'VALUE']
   
   
   return (
      <div>
         <div>Transactions</div>
         <table className="w-full table-auto text-sm border-collapse border border-slate-400">
            <thead>
               <tr>{ table_header.map((name) => <th className="border border-slate-300">{name}</th>)}</tr>
            </thead>
            <tbody className="text-xs text-right">
               <tr>{ table_body.map((value) => <td className="border border-slate-300">{value}</td>)}</tr>
            </tbody>
         </table>
      </div>
   )
}