import { createContext, useState } from "react";

export const Profile = createContext()
export const ProfileContext = ({children}) => {
   const [profile, setProfile] = useState()
   return (
      <Profile.Provider value={[profile, setProfile]}>
         <div>{children}</div>
      </Profile.Provider>       
   )
}

export const Config = createContext()
export const ConfigContext = ({children}) => {
   const [config, setConfig] = useState({ language: 'en', page: 'home' })
   return (
      <Config.Provider value={[config, setConfig]}>
         <div>{children}</div>
      </Config.Provider>       
   )
}


export const Redeem = createContext()
export const RedeemContext = ({children}) => {
   const [redeem, setRedeem] = useState({ process: '1', userPoint: 0, selected: [], items: [], amount: 0 })
   return (
      <Redeem.Provider value={[redeem, setRedeem]}>
         <div>{children}</div>
      </Redeem.Provider>       
   )
}

export const Account = createContext()



