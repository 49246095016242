import { useEffect, useState } from "react"



const Transactions = () => {
   const [users, setUsers] = useState([])
   const [update, setUpdate] = useState(new Date())
   const [transactions, setTransactions] = useState([]) 
   const [filter, setFilter] = useState('all')
   const [filterTransactions, setFilterTransactions] = useState([])

   useEffect(() => { fetchUsers({setUsers}) }, [])
   useEffect(() => { fetchTransactions({setTransactions}) }, [update])
   useEffect(() => { 
      let filterData = transactions.filter((transaction) => filter === 'all' || transaction.status === filter ) 
      setFilterTransactions(filterData)
   }, [transactions, filter])

   const handleChangeFilter = (event) => { setFilter(event.target.value) }

   return(
      <div className="p-4 border grid gap-2">
         <div className="flex gap-2 justify-between"> 
            <div className="text-xl font-bold ">Redeem Transactions</div>
            
            <div className="grid gap-1 sm:flex sm:gap-2">
               <div className="flex items-center gap-1 ml-auto">
                  <div>Filter</div>
                  <select className="border px-1" value={filter} onChange={handleChangeFilter}>
                     <option value="all">All</option>
                     <option value="pending">Pending</option>
                     <option value="processing">Processing</option>
                     <option value="reject">Reject</option>
                     <option value="success">Success</option>
                  </select>               
               </div>
               <button className="text-sm flex gap-1 items-center px-2 border self-center text-center"
                  onClick={() => { setUpdate(new Date())} }>
                  Refresh <i className="material-icons text-lg">refresh</i>
               </button>
            </div>
         </div>
         <div className="grid gap-2">
            { filterTransactions.map((transaction) => <TransactionItem users={users} transaction={transaction} setUpdate={setUpdate}/>) }
         </div>
      </div>
   )
}

export default Transactions

const TransactionItem = ({users, transaction, setUpdate}) => {
   const [user, setUser] = useState({})
   const [showItems, setShowItems] = useState(false)

   useEffect(() => {
      setUser(users.find((u) => u?.line?.profile?.userId === transaction?.userId))
   }, [transaction, users])

   const handleChangeStatus = (event) => { 
      let status = event.target.value
      let transactionId = transaction.transactionId
      fetchChangeRedeemStatus({ transactionId, status });
      setUpdate(new Date()) 
   }
   const handleClickShowItems = () => { setShowItems(!showItems) }
   const handleClickComplete = () => { 
      let transactionId = transaction.transactionId
      fetchChangeRedeemComplete({ transactionId, complete: true });
      setUpdate(new Date()) 
   }

   let status = transaction.status
   let backgroundColor = !status ? 'bg-gray-100'
      : status === 'pending' ? 'bg-gray-100' 
      : status === 'processing' ? 'bg-yellow-100' 
      : status === 'reject' ? 'bg-red-100'
      : status === 'success' ? 'bg-green-100'
      : ''
   let buttonColor = !status ? 'bg-gray-200'
      : status === 'pending' ? 'bg-gray-200'
      : status === 'processing' ? 'bg-yellow-200' 
      : status === 'reject' ? 'bg-red-200'
      : status === 'success' ? 'bg-green-200'
      : ''

   return (
      <div className={`border p-2 grid gap-2 ${backgroundColor}`} >
         <div className="grid gap-1">
            <div className="flex justify-between">
               <div className="text-xs">TransactionId : {transaction.transactionId}</div>
               
               <button className="disabled:opacity-30" onClick={handleClickComplete} disabled={transaction.complete}>
                  <i className="material-icons" >{ transaction.complete?'task_alt':'schedule'}</i>                  
               </button>
               

            </div>
            <div className="text-xs"> {new Date(transaction.datetime).toLocaleString('th-TH')} </div>
            
            <div className="flex gap-1">

               <img className="w-6 h-6 rounded-full text-xs" alt="profile" src={user?.line?.profile.pictureUrl} />
               <div className="text-sm" >{user?.line?.profile.displayName}</div>

               <div className="ml-auto text-sm flex items-center gap-1">
                  <div>Status</div>
                  <select className={`border px-1 ${buttonColor} disabled:opacity-30`} onChange={handleChangeStatus} value={status} disabled={transaction.complete}>
                     <option className="text-gray-500 bg-white" value="pending">Pending</option>
                     <option className="text-yellow-500 bg-white" value="processing">Processing</option>
                     <option className="text-red-500 bg-white" value="reject">Reject</option>
                     <option className="text-green-500 bg-white" value="success">Success</option>
                  </select>
               </div>
            </div>
         </div>
         <div className="flex justify-between">
            <div className="flex gap-2 flex-wrap">
               
               { transaction.items.map((item) => 
                  <div>
                     {/* {JSON.stringify(item)} */}
                     { showItems
                        ?  <div className="border rounded shadow p-2 bg-white">
                              <div className="text-sm">{item.name}</div>
                              <img className="w-20 mx-auto" alt="redeem-item" src={item.pictureUrl} />
                              <div>Volume: {item.volume}</div>
                           </div>
                        :  <div className="border rounded shadow px-1 bg-white">
                              <div className="text-xs">{item.name} x {item.volume}</div>
                           </div>
                     }

                  </div> ) }
            </div>   
            <div className="flex items-start">
                              <button className="border rounded-full px-2 bg-white text-xs self-start"
                  onClick={handleClickShowItems}><i className="material-icons text-xs">{ showItems?'expand_less':'expand_more' }</i>
               </button>   
               <div className="w-24 text-right text-sm"> Point: <span className="text-lg">{ Number(transaction.items.reduce((prev, curr) => prev+Number(curr.value), 0)).toLocaleString() }</span> </div>
             
            </div>
         </div>
      </div>
   )
}


const fetchTransactions = async ({setTransactions}) => {
   fetch('/mtscapital/app/redeem/transactions/redeem')
   .then((response) => response.json())
   .then((data) => { setTransactions(data.transactions) })
}

const fetchUsers = async ({setUsers}) => {
   fetch(`/mtscapital/app/redeem/users`)
   .then((response) => response.json())
   .then((data) => { setUsers(data.users) })
}

const fetchChangeRedeemStatus = async ({ transactionId, status }) => {
   fetch('/mtscapital/app/redeem/transaction/status', {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ transactionId, status })
   })
   .then((response) => response.json())
   .then((data) => { console.log(data); })
}

const fetchChangeRedeemComplete = async ({ transactionId, complete }) => {
   fetch('/mtscapital/app/redeem/transaction/complete', {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ transactionId, complete })
   })
   .then((response) => response.json())
   .then((data) => { console.log(data); })
}


