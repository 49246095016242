import React, { useState, useEffect } from 'react'

import Broadcast from './Broadcast'
import logo from './logo.png'

export default function MTSGold () {
   const [menu, setMenu] = useState('broadcast')
   const [check, setCheck] = useState(false)
   const [channel, setChanel] = useState('test')

   useEffect(() => { setChanel(check? 'mtsgold' : 'test') }, [check])
   
   const handleClickBroadcastMenu = () => { setMenu('broadcast') }

   return (
      <div>
         <nav className="bg-blue-900 px-3 py-1 flex justify-between items-center" >
            <div className="flex items-center">
               <img src={logo} alt="logo" className="w-20"/>
               <div className="text-white px-2"> MTS Gold Line Official </div>
            </div>
            <button className="text-white px-2" onClick={handleClickBroadcastMenu}> Boradcast </button>
            <div className="flex items-center cursor-pointer"
               onClick={() => { setCheck(!check) }}>
               <div className="text-white px-2 font-semibold">Channel : {check? 'MTS Gold': 'Test'} </div>
               <div className={`material-icons-outlined text-3xl ${check ? 'text-green-300' : 'text-red-300'}`}>{check? 'toggle_on' : 'toggle_off'}</div>
            </div>
         </nav>
         { menu === 'broadcast' && <Broadcast channel={channel} /> }
      </div>
   )
}