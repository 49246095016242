import React, { useState } from 'react'
import Menu from './Menu'
import Dashboard from './Dashboard'
import Users from './Users'
import Alerts from './Alerts'
import Richmenu from './Richmenu'


export default function MTSPlus() {
   const [menu, setMenu] = useState('dashboard')
   const MenuProps = {menu, setMenu}

   return (
      <div className="grid">
         <Menu {...MenuProps}/>
         <div className="justify-self-center">
            { menu === 'dashboard' && <Dashboard /> }
            { menu === 'alerts' && <Alerts /> }
            { menu === 'richmenu' && <Richmenu /> }
            { menu === 'users' && <Users /> }            
         </div>

      </div>
   )
}

