import React from 'react'
import logo from './logo.png'

const Menu = ({menu, setMenu}) => {
   const itemSetting = { name: 'setting', icon: 'settings' }
   const menuList = [
      { name: 'dashboard', icon: 'dashboard' },
      { name: 'users', icon: 'people' },
      { name: 'alerts', icon: 'send' },
      { name: 'richmenu', icon: 'view_quilt' },
   ]
   return (
      <div className="flex justify-between bg-blue-900 py-3 px-6">
         <img className="object-cover w-20 h-6" src={logo} alt="menu" />
         <div className="flex gap-6">
            { menuList.map((item, i) => <MenuItem key={i} item={item} menu={menu} setMenu={setMenu} />) }
         </div>
         <div className="">
            <MenuItem item={itemSetting} menu={menu} setMenu={setMenu} />
         </div>
      </div>
   )
}

const MenuItem = ({item, menu, setMenu}) => {
   let active = (menu === item.name)?'text-white':'text-blue-200'

   return (
      <div className={`flex gap-2 cursor-pointer ${active}`}
         onClick={() => { setMenu(item.name) }}>
         <i className="material-icons">{item.icon}</i>
         <div className="capitalize">{item.name}</div>
      </div>
   )
}

export default Menu
