import { useState, useEffect, useRef, useContext } from 'react'
// import * as htmlToImage from 'html-to-image'
import liff from "@line/liff"

import { Config, Profile, Redeem } from "../../Context"
import Receipt from "./Receipt"



const RedeemMain = () => {  
   const [redeem, setRedeem] = useContext(Redeem)

   useEffect(() => { 
      fetch('/mtscapital/line/liff/redeem/items')
      .then((response) => response.json())
      .then((data) => { setRedeem((redeem) => ({...redeem, items: data.items})) })
      .catch((error) => { console.log('Get Redeem Items Error');})
   }, [setRedeem])

   return (
      <div className="text-center w-full mt-16" >
         <div className="rounded-t-3xl bg-gray-100 p-4" >
            {/* <div className="flex justify-between">
               <BackToMainButton />
            </div> */}
            <div className="gap-2" style={{height: 'calc(100vh - 140px)'}}>
               <RedeemProcess />
               { redeem.process === '1' && <RedeemChooseItems /> }
               { redeem.process === '2' && <RedeemConfirm /> }
               { redeem.process === '3' && <RedeemTransaction /> }
            </div>           
         </div>        
      </div>
   )

}
export default RedeemMain

const RedeemProcess = () => {
   const [config] = useContext(Config)
   const [redeem, setRedeem] = useContext(Redeem)

   const handleClickChangeProcess = (event) => { 
      if(event.target.value === '2') {
         if(redeem.amount === 0) setRedeem((data) => ({...data, result: (config.language === 'en') ? 'Please choose one' : 'เลือกอย่างน้อย 1 ชิ้น'}) )     
         else if(redeem.amount > redeem.point) setRedeem((data) => ({...data, result: (config.language === 'en') ? 'Redeem Point more than you have' : 'คะแนนทีใช้แลกมากกว่าคะแนนที่มีอยู่'}) ) 
         else setRedeem((data) => ({...data, process: event.target.value}) ) 
      } else {
         setRedeem((data) => ({...data, process: event.target.value}) ) 
      }
   }

   return (
      <div className="w-full py-2">
         <div className="flex items-center">
            <div className="flex-1"></div>
            <button className="bg-blue-400 w-2 h-2 rounded-full" 
               disabled={redeem.process === '3'} value="1" onClick={handleClickChangeProcess}></button>
            <div className={`${+redeem.process > 1 ? 'bg-blue-400' : 'bg-gray-300'} h-1 flex-1`}></div>
            <div className={`${+redeem.process > 1 ? 'bg-blue-400' : 'bg-gray-300'} h-1 flex-1`}></div>
            <button className={`${+redeem.process > 1 ? 'bg-blue-400' : 'bg-gray-400'} w-2 h-2 rounded-full`} 
               disabled={redeem.process === '3'} value="2" onClick={handleClickChangeProcess}></button>
            <div className={`${+redeem.process > 2 ? 'bg-blue-400' : 'bg-gray-300'} h-1 flex-1`}></div>
            <div className={`${+redeem.process > 2 ? 'bg-blue-400' : 'bg-gray-300'} h-1 flex-1`}></div>
            <button className={`${+redeem.process > 2 ? 'bg-blue-400' : 'bg-gray-400'} w-2 h-2 rounded-full`}
               disabled={redeem.process === '3'}></button>
            <div className="flex-1"></div>
         </div> 
         <div className="grid grid-cols-3 items-center text-sm">
            <button className={`${+redeem.process >= 1 ? 'font-semibold text-blue-400': 'text-gray-400'} `}
               disabled={redeem.process === '3'} value={1} onClick={handleClickChangeProcess}> 
               { config.language === 'en' ? 'Choose' : 'เลือกสินค้า'}
            </button>
            <button className={`${+redeem.process >= 2 ? 'font-semibold text-blue-400': 'text-gray-400'} `}
               disabled={redeem.process === '3'} value={2} onClick={handleClickChangeProcess}>
               { config.language === 'en' ? 'Confirm' : 'ยืนยันรายการ'}
            </button>
            <button className={`${+redeem.process === 3 ? 'font-semibold text-blue-400': 'text-gray-400'} `}
               disabled={redeem.process === '3'} value={3} >
               { config.language === 'en' ? 'Complete' : 'ทำรายการสำเร็จ'}
            </button>
         </div>
      </div>
   )
}


const RedeemChooseItems = () => {
   const [redeem] = useContext(Redeem)

   return (
      <div className="px-2 my-2 mx-auto overflow-y-auto" style={{height: 'calc(100vh - 240px)'}}>
         <div className="pb-2 grid gap-2 sm:gap-3 md:gap-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 overflow-hidden">
            { redeem.items.map((item) => <RedeemItem key={item.id} item={item} /> ) }
         </div>
         <div className="fixed left-0 bottom-14 py-1 w-full px-2">
            <RedeemFooter />
         </div>
      </div>
   )
}

const RedeemItem = ({item}) => {
   const [config] = useContext(Config)
   const [redeem, setRedeem] = useContext(Redeem)
   const count = redeem.selected.filter((itemId) => item.id === itemId).length

   const handleClickAddItem = () => { setRedeem((data) => ({...data, selected: [...data.selected, item.id]}) ) }
   const handleClickDelItem = () => { 
      setRedeem((data) => {
         let array = [...data.selected];
         array.splice(array.indexOf(item.id), 1)
         return {...data, selected: array}
      })
   }

   return (
      <div className="grid gap-1">
         <div className="border shadow bg-white " >
            <div className="relative">
               <div className="absolute font-bold py-1 px-2">{item.name} </div>
               {/* <div className="absolute top-1 right-2 text-sm"> { config.language === 'en' ? 'Redeemed' : 'แลกไปแล้ว' } {item.used || 0} </div> */}
               <img src={item.pictureUrl} className="object-cover py-2" alt="redeem-item" />
               <div className="absolute bottom-0 w-full py-1 px-2">
                  
                  <div className="flex gap-2 justify-center items-baseline bg-blue-900/60 rounded-full text-white px-4">
                     <div className="text-sm"> { config.language === 'en' ? 'point' : 'คะแนน' } </div>
                     <div className="text-xl">{item.value}</div>  
                  </div>
               </div>
            </div>
         </div>  
         <div className={`flex gap-1 ${!item.publish?'opacity-30':''}`}>
            <button className="flex-1 bg-red-600 text-white px-3 rounded-md shadow disabled:opacity-50 text-2xl" disabled={item.total-item.used <= 0 || count <= 0}
               onClick={handleClickDelItem}  >-</button> 
            <input value={count} className="w-12 border text-center" disabled={true} /> 
            <button className="flex-1 bg-green-600 text-white px-3 rounded-md shadow disabled:opacity-50 text-2xl" disabled={item.total-item.used-count <= 0 || count >= 10}
               onClick={handleClickAddItem} >+</button>  
         </div>                
      </div>
   )
}

const RedeemFooter = () => {
   const [config] = useContext(Config)
   const [redeem, setRedeem] = useContext(Redeem)

   useEffect(() => {
      let itemsSelect = redeem.selected.map((id) => redeem.items.find((item) => item.id === id))
      setRedeem((data) => ({...data, amount: itemsSelect.reduce((prev, item) => prev+(+item?.value), 0)}))
   }, [redeem.items, redeem.selected, setRedeem])

   const handleClickRedeemItems = () => { 
      if(redeem.amount === 0) setRedeem((redeem) => ({...redeem, error: (config?.language === 'en') ? 'Please choose one' : 'เลือกอย่างน้อย 1 ชิ้น'}) )    
      else if(redeem.amount > redeem.userPoint) setRedeem((redeem) => ({...redeem, error: (config?.language === 'en') ? 'More than you have' : 'คะแนนทีใช้แลกมากกว่าคะแนนที่มีอยู่'}) )  
      else setRedeem((redeem) => ({...redeem, process: '2', error: ''})) 
   }

   return (  
      <div className="flex justify-between pl-4 pr-2 bg-blue-900 rounded-full">
         <div className="text-left text-white my-auto">
            <div className="text-lg">{ (config.language === 'en') ? 'Your Point : ' : 'คะแนนสะสม : '} {Number(redeem.userPoint).toLocaleString()}</div>
            <div className="text-red-400 text-xs">{redeem.error}</div>
         </div>
         <div className="flex gap-4 items-center py-2">
            {/* <div className={(redeem.amount > redeem.userPoint) ? 'text-red-600' : ''}> { (config.language === 'en') ? 'Point : ' : 'คะแนน : '}  </div> */}
            <button className="rounded-full px-3 py-1 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-semibold"
               onClick={handleClickRedeemItems}>
               { (config.language === 'en') ? 'Redeem' : 'แลกของ'} : {redeem.amount}
            </button>
         </div>
      </div>
   )
}

const RedeemConfirm = () => {
   const [profile] = useContext(Profile)
   const [config, setConfig] = useContext(Config)
   const [redeem, setRedeem] = useContext(Redeem)
   const itemSet = [...new Set(redeem.selected)].map((id) => redeem.items.find((item) => item.id === id))

   const [showRedeemOTP, setShowRedeemOTP] = useState(false)
   const [countdownOTP, setCountdownOTP] = useState(0)
   const [intervalId, setIntervalId] = useState()
   const [result, setResult] = useState()

   useEffect(() => {
      if(countdownOTP < 0) clearInterval(intervalId)
   }, [countdownOTP, intervalId])

   useEffect(() => {
      if(result?.status === 'success') {
         clearInterval(intervalId)
         setTimeout(() => { setRedeem((redeem) => ({...redeem, transactionId: result?.transactionId, process: '3' })) }, 2000) 
      }
   }, [intervalId, result, setConfig, setRedeem])

   const handleClickRedeemCancel = () => { setRedeem((redeem) => ({...redeem, selected: [], process: '1' })) }
   const handleClickRedeemConfirm = () => { setShowRedeemOTP(true);  }
   const handleClickRedeemSendOTP = () => { 
      fetch('/mtscapital/line/liff/transaction/redeem/sendotp', { 
         method: 'POST', 
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({ userId: profile?.userId, language: config.language }) 
      })
      .then((response) => response.json())
      .then((data) => { setResult(data) })
      .catch()

      setCountdownOTP(60); 
      setIntervalId(setInterval(() => { 
         setCountdownOTP((countdown) => countdown-1 ) 
         
      }, 1000)) 
   }

   const handleChangeInputOTP = (event) => { 
      if(event.target.value.length === 6) {
         fetch('/mtscapital/line/liff/transaction/redeem/checkotp', { 
            method: 'POST', 
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
               language: config?.language, 
               userId: profile?.userId, 
               code: event.target.value, 
               value: redeem.amount,
               ref: result.ref,
               selected: redeem.selected
            }) 
         })
         .then((response) => response.json())
         .then((data) => { setResult(data) })
         .catch(() => { console.log('Check OTP Error'); })
         event.target.value = ''
      } 
   }
   


   return (
      <div className="mx-4 my-4 overflow-y-auto" style={{height: 'calc(100vh - 200px)'}}>
         { itemSet.map((item) => <RedeemConfirmItem key={'confirm-'+item?.id} item={item}/>) }
         <hr className="my-2" />
         <div className="px-4 text-lg text-right">Summary : {redeem.amount}</div>
         <div className="grid grid-cols-2 gap-2 my-4">
            <button className="bg-gray-400 text-white rounded py-1" onClick={handleClickRedeemCancel} >
               { config.language === 'en' ? 'Cancel' : 'ยกเลิกรายการ' }</button>
            <button className="bg-blue-900 text-white rounded py-1" onClick={handleClickRedeemConfirm} >
               { config.language === 'en' ? 'Confirm' : 'ยืนยันคำสั่ง' }</button>
         </div>
         { showRedeemOTP && 
            <div className="w-60 mx-auto">
               { countdownOTP <= 0 
                  ? <button className="bg-orange-400 rounded text-white px-4 py-1 w-full" onClick={handleClickRedeemSendOTP}>
                        { config.language === 'en' ? 'Send OTP' : 'ส่ง OTP' }</button>
                  :  <div >
                        <div className="flex justify-between text-sm"> 
                           <div className="">{`Ref: ${result?.ref || ''}`}</div>
                           <div className={(result?.status === 'success')? 'text-green-600' : 'text-red-600'}>{result?.message}</div>
                        </div>
                        <input className="border text-center p-1 w-full" type="text" 
                           disabled={(result?.status === 'success')}
                           placeholder={ config.language === 'en' ? `Input OTP (Sec ${countdownOTP})` : `กรอก OTP (วินาที ${countdownOTP})` }
                           onChange={handleChangeInputOTP} />
                     </div>
               }
            </div> 
         }
      </div>
   )
}

const RedeemConfirmItem = ({item}) => {
   const [redeem] = useContext(Redeem)
   const count = redeem.selected.filter((id) => id === item.id).length

   return (
      <div className="flex items-center text-sm">
         <img className="w-12" src={item.pictureUrl} alt="item" />
         <div className="grow">{item.name}</div>
         <div className="px-4">{count} x {item.value}</div>
         <div className="px-4">{count*item.value}</div>
      </div>
   )
}

const RedeemTransaction = () => {
   const [config, setConfig] = useContext(Config)
   const [redeem, setRedeem] = useContext(Redeem)
   const receiptRef = useRef()

   const handleClickBackToHome = () => {
      setConfig((config) => ({...config, page: 'home'}))
      setRedeem((redeem) => ({...redeem, process: '1', selected: [] }))
   }

   const handleClickSendtoLine = () => { 
      liff.sendMessages([{
         "type": "image",
         "originalContentUrl": `https://line.mtsbotic.com/mtscapital/line/liff/receipt/${redeem.transactionId}`,
         "previewImageUrl": `https://line.mtsbotic.com/mtscapital/line/liff/receipt/${redeem.transactionId}`
     }]);
   }

   // const handleClickSaveFile = () => { 
   //    htmlToImage.toPng(receiptRef.current).then((dataUrl) => { 
   //       const link = document.createElement('a')
   //       link.download = `MTS_Redeem_${redeem?.transactionId.slice(2,15)}.png`
   //       link.href = dataUrl
   //       link.click()
   //     })
   // }


   // const handleClickShare = () => {    
   //    htmlToImage.toBlob(receiptRef.current).then((blob) => { 
   //       let file = new File([blob], "picture.jpg", {type: 'image/jpeg'});
   //       let filesArray = [file];

   //       if(navigator.canShare && navigator.canShare({ files: filesArray })) {
   //          navigator.share({        
   //             text: 'Test',
   //             files: filesArray,
   //             title: 'Test Share File',
   //             url: 'https://www.mtscapitals.com'
   //          });
   //       }
   //    })
   // } 

   // const handleClickSavePDF = () => { 
   //    htmlToImage.toCanvas(receiptRef.current).then((canvas) => { 
   //       document.body.appendChild(canvas);
   //    })
   // }

   return (
      <div className="overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
         <div className="my-4 grid gap-4 px-4 overflow-y-auto" >
            <Receipt receiptRef={receiptRef} transactionId={redeem.transactionId} />
            <div className="grid grid-cols-2 gap-2">
               <button className="bg-gray-200 py-1 rounded flex justify-center items-center gap-1" onClick={handleClickBackToHome}>
                  <i class="material-icons">home</i>
                  { config.language === 'en' ? 'Back to Main' : 'กลับสู่หน้าหลัก' }
               </button>
               <button className="bg-green-200 py-1 rounded flex justify-center items-center gap-1" onClick={handleClickSendtoLine}>
                  <i class="material-icons">reply</i> 
                  { config.language === 'en' ? 'Send to Line' : 'ส่งไปที่ Line' }
               </button>

               {/* <button className="bg-green-200 py-1 rounded" onClick={handleClickSaveFile}>
                  { config.language === 'en' ? 'Save Transaction' : 'บันทึกรายการ' }
               </button>
               <button className="bg-blue-200 py-1 rounded" onClick={handleClickShare}>
                  { config.language === 'en' ? 'Share' : 'แบ่งปัน' }
               </button>
               <button className="bg-red-200 py-1 rounded" onClick={handleClickSavePDF}>
                  { config.language === 'en' ? 'Save PDF' : 'บันทึก PDF' }
               </button> */}
            </div>
         </div>

      </div>

   )
}
