import { useContext } from "react"
import { Config } from "../../Context"


// import flag_en from "./Images/flag_en.png"
// import flag_th from "./Images/flag_th.png"
// const flag = { en: flag_en, th: flag_th }

const Language = () => {
   const [config, setConfig] = useContext(Config)

   const handleClickChangeLanguage = () => { setConfig((prev) => ({...prev, language: prev.language === 'en' ? 'th' : 'en' }) ) }
   return (
      <button className="px-2" onClick={handleClickChangeLanguage}>
         {/* <img src={flag[config?.language]} alt="flag" /> */}
         <div className="text-white">{config.language.toUpperCase()}</div>
      </button>
   )
}

export default Language