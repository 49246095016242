import { useState, useEffect, useContext, useRef } from "react"
import liff from "@line/liff"

import { Profile, Config } from "../../Context"
import Receipt from "./Receipt"




const History = () => {
   const [profile] = useContext(Profile)
   const [config]= useContext(Config)

   const receiptRef = useRef(null)

   const [update, setUpdate] = useState([])
   const [disableRefresh, setDisableRefresh] = useState(false)

   const [transactions, setTransactions] = useState([])
   const [showReceipt, setShowReceipt] = useState(false)
   const [transactionId, setTransactionId] = useState()


   useEffect(() => {
      fetch(`/mtscapital/line/liff/transactions/user/${profile?.userId}`)
      .then((response) => response.json())
      .then((data) => { setTransactions(data.transactions) } )
      .catch(() => { console.log('Get Transaction Error') })
   }, [profile, update])

   // useEffect(() => {
   //    if(showReceipt && transactionId) {
   //       console.log(transactionId);
   //       htmlToImage.toBlob(receiptRef.current)
   //       .then((blob) => { 
   //          console.log(`redeem_${transactionId.slice(-8)}.png`);
   //          let formData = new FormData();
   //          formData.append('imageFile', blob, `redeem_${transactionId.slice(-8)}.png`);
   //          fetch('/mtscapital/line/liff/transaction/redeem/receipt', { method: 'post', body: formData })
   //       })
   //    }
   // }, [showReceipt, transactionId])


   let total = transactions.reduce((prev, curr) => prev + Number(curr.value) , 0)

   const handleClickCloseReceiptModal = () => { setShowReceipt(false) }
   // const handleClickSaveReceipt = () => {
   //    let url = `https://line.mtsbotic.com/mtscapital/line/liff/receipt/${transactionId}`
   //    liff.openWindow({ url: url, external: true })
   // }

   const handleClickSendtoLine = () => { 
      liff.sendMessages([{
         "type": "image",
         "originalContentUrl": `https://line.mtsbotic.com/mtscapital/line/liff/receipt/${transactionId}`,
         "previewImageUrl": `https://line.mtsbotic.com/mtscapital/line/liff/receipt/${transactionId}`
     }]);
   }



      // htmlToImage.toPng(receiptRef.current)
      // .then((dataUrl) => { 
      //    console.log(dataUrl);
      //    liff.openWindow({
      //       url: dataUrl,
      //       external: true
      //    })
         // const link = document.createElement('a')
         // link.download = `MTS_Redeem_${transactionId.slice(2,15)}.png`
         // link.href = "javascript:void(0)"
         // link.click()


   const handleClickRefresh = () => { 
      setDisableRefresh(true)
      setTimeout(() => { setDisableRefresh(false) }, 1000)
      setUpdate([]) 
   }

   return (
      <div className="text-center w-full mt-16">
         <div className="bg-gray-100 p-4 rounded-t-3xl" >
            <div className="font-bold my-2 flex justify-between">
               <div></div>
               <div className="">{ config.language === 'en' ? 'History Transaction' : 'ประวัติรายการ' }</div>
               <button className="material-icons disabled:animate-spin" disabled={disableRefresh}  
                  onClick={handleClickRefresh}>refresh</button>
            </div>          
            <div className="overflow-y-auto" style={{height: 'calc(100vh - 180px)'}} >
               { transactions.map((item) => <HistoryItem key={item.transactionId} item={item} setShowReceipt={setShowReceipt} setTransactionId={setTransactionId}/>) }
               <hr className="my-2"/>
               <div className="grid grid-cols-4 p-1 items-center"> 
                  <div className="col-span-2"></div>
                  <div className="col-span-1">
                     { config.language === 'en' ? 'Total' : 'ยอดรวม' }
                  </div>
                  <div className="col-span-1 text-xl"> {Number(total).toLocaleString()} </div>
                  
               </div>               
            </div>
         </div>
         { showReceipt && 
            <div className="fixed top-0 w-full h-screen bg-gray-800/60 flex items-center justify-center">
               <div>
                  <Receipt receiptRef={receiptRef} transactionId={transactionId} />
                  <div className="flex justify-evenly text-white py-2">
                     <button className="flex items-center gap-1" onClick={handleClickSendtoLine}>
                        <i class="material-icons">reply</i>
                        { config.language === 'en' ? 'Send to Line' : 'ส่งไปที่ Line' }
                     </button>
                     <button className="flex items-center gap-1" onClick={handleClickCloseReceiptModal}> 
                        <i className="material-icons">close</i>
                        { config.language === 'en' ? 'Close' : 'ปิด' } 
                     </button>
                  </div>
                  
               </div>
            </div>
         }

      </div>
   )
}

const HistoryItem = ({ item, setShowReceipt, setTransactionId }) => {
   const [config]= useContext(Config)

   const [showDetail, setShowDetail] = useState(false)

   const handleClickshowDetail = () => { setShowDetail((prev) => !prev) }
   const handleClickShowTransaction = () => { 
      if(item.type === 'redeem') {
         setShowReceipt(true); 
         setTransactionId(item.transactionId);          
      }
   }

   return (
      <div className="cursor-pointer py-1" onClick={handleClickshowDetail}>
         <div className="grid grid-cols-7 gap-2" >
            <div className="col-span-2 text-sm">{item?.datetime?.slice(0, 10)}</div>
            <div className="col-span-3 text-left text-sm"> 
               <div className="flex justify-between items-center">
                  <div>{item.title}</div>
                  <div className="text-xs">{item.authorize}</div>
               </div>
            </div>
            <div className="col-span-2">{item.value}</div>
         </div>
         { showDetail && 
            <div className="grid grid-cols-7" >
               <div className="col-span-2">{}</div>
               <div className="col-span-3 text-left text-xs">
                  <div className="truncate" onClick={handleClickShowTransaction}>id: {item.transactionId} </div>   
                  { config.language === 'en' ? 'Detail: ': 'รายละเอียด: '} {item.detail}</div>
            </div>   
         }
      </div>

   )
}

export default History