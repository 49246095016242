import { Profile, ProfileContext, Config, ConfigContext, RedeemContext } from "./Context";
import Header from "./Components/Header/Header"
import Body from "./Components/Body/Body"
import Footer from "./Components/Footer/Footer"

import Background from "./Images/Background.svg"
import { useContext, useEffect, useState } from "react";

const LiffRedeem = () => {
   const [update, setUpdate] = useState(new Date())
   const [showModalAgreement, setShowModalAgreement] = useState(null)

   useEffect(() => { document.title = "MTS Point"}, [])
   console.log(showModalAgreement);


   return(
      <ConfigContext>
         <ProfileContext>
            <RedeemContext>
               <LineProfile update={update} setShowModalAgreement={setShowModalAgreement}/>               
               <div className="w-full h-screen bg-gray-200 m-0 p-0">
                  <img className="fixed w-full -top-32 " src={Background} alt="background" /> 
                  <div className="fixed w-full">
                     <Header />
                     { showModalAgreement === false && <Agreement setUpdate={setUpdate}/> }
                     { showModalAgreement && <Body /> }
                     { showModalAgreement && <Footer /> }                                
                  </div>
               </div>   
            </RedeemContext>
         </ProfileContext>
      </ConfigContext>
   )
}

export default LiffRedeem

const Agreement = ({ setUpdate }) => { 
   const [config] = useContext(Config)
   const [profile] = useContext(Profile)
   const [agreement, setAgreement] = useState({ term: false, pdpa: false })

   useEffect(() => {
      fetchUpdateAgreement(agreement, profile, setUpdate);
   }, [agreement])

   const handleClickUpdateTerm = () => { setAgreement({ term: true, pdpa: false }) }
   const handleClickCancelTerm = () => { }
   
   const handleClickUpdatePDPA = () => { setAgreement({ term: true, pdpa: true }) }

   return (
      <div className="flex h-screen">
         <div className="my-auto mx-auto w-5/6">
            { agreement.term === false && 
               <div className="border bg-white rounded-xl">
                  <div className="mx-6 my-4"> {config.language === 'en' ? 'Terms and Conditions' : 'ข้อกำหนดและเงื่อนไข' }</div>
                  <div className="overflow-x-auto h-96 mx-6 my-4 text-sm">
                     Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto quibusdam recusandae et pariatur debitis, iste quo accusamus. Ea dolores aliquam, tempore veritatis possimus, tempora, tenetur mollitia itaque quaerat et id?
                     Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cupiditate beatae nemo in iste nostrum, voluptas magnam nisi asperiores voluptatum minus nulla repellendus quos placeat dignissimos consequuntur velit quae dolores quaerat!
                     Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex molestias optio vel beatae veritatis sequi doloremque facere iste numquam tenetur dolore temporibus neque qui, nam nobis provident voluptate nemo facilis?
                     Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui id laboriosam vero nihil explicabo. Voluptatem, temporibus laboriosam dolore vel quae nobis. Delectus nisi impedit molestiae nulla, eveniet explicabo doloremque temporibus?
                     Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo ducimus tempore repellat a repellendus, eaque, repudiandae, maxime sint hic illum sunt minima adipisci vitae. Nihil placeat voluptatem eveniet autem explicabo?
                     Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias harum, perspiciatis repellat asperiores itaque, enim cupiditate corporis impedit labore quia tenetur magnam totam odio tempore doloribus quas sint laborum quisquam.
                     Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium amet odit voluptates, iusto fugiat officia, tempore cum assumenda, alias necessitatibus aperiam harum magni! Debitis necessitatibus enim est ea laboriosam minima?
                     Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere vitae adipisci quasi officiis dolorem odit ad earum consequatur animi! Mollitia voluptas possimus dolorem modi corporis incidunt obcaecati sapiente repudiandae eligendi!
                  </div>
                  <div className="mx-6 my-4 flex gap-2 justify-end ">
                     <button className="text-white bg-blue-800 rounded-full px-4 py-1"
                        onClick={handleClickUpdateTerm}>{config.language === 'en' ? 'Accept' : 'ยอมรับ' }</button>
                     <button className="text-white bg-gray-300 rounded-full px-4 py-1"
                        onClick={handleClickCancelTerm}>{config.language === 'en' ? 'Cancel' : 'ไม่ยอมรับ' }</button>
                  </div>
               </div>            
            }
            { agreement.term && !agreement.pdpa &&
               <div className="border bg-white rounded-xl">
                  <div className="mx-6 my-4"> {config.language === 'en' ? 'PDPA' : 'ยินยอมเปิดเผยข้อมูลส่วนบุคคล' }</div>
                  <div className="overflow-x-auto h-96 mx-6 my-4 text-sm">
                     Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto quibusdam recusandae et pariatur debitis, iste quo accusamus. Ea dolores aliquam, tempore veritatis possimus, tempora, tenetur mollitia itaque quaerat et id?
                     Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cupiditate beatae nemo in iste nostrum, voluptas magnam nisi asperiores voluptatum minus nulla repellendus quos placeat dignissimos consequuntur velit quae dolores quaerat!
                     Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex molestias optio vel beatae veritatis sequi doloremque facere iste numquam tenetur dolore temporibus neque qui, nam nobis provident voluptate nemo facilis?
                     Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui id laboriosam vero nihil explicabo. Voluptatem, temporibus laboriosam dolore vel quae nobis. Delectus nisi impedit molestiae nulla, eveniet explicabo doloremque temporibus?
                     Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo ducimus tempore repellat a repellendus, eaque, repudiandae, maxime sint hic illum sunt minima adipisci vitae. Nihil placeat voluptatem eveniet autem explicabo?
                     Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias harum, perspiciatis repellat asperiores itaque, enim cupiditate corporis impedit labore quia tenetur magnam totam odio tempore doloribus quas sint laborum quisquam.
                     Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium amet odit voluptates, iusto fugiat officia, tempore cum assumenda, alias necessitatibus aperiam harum magni! Debitis necessitatibus enim est ea laboriosam minima?
                     Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere vitae adipisci quasi officiis dolorem odit ad earum consequatur animi! Mollitia voluptas possimus dolorem modi corporis incidunt obcaecati sapiente repudiandae eligendi!
                  </div>
                  <div className="mx-6 my-4 flex gap-2 justify-end ">
                     <button className="text-white bg-blue-800 rounded-full px-4 py-1"
                        onClick={handleClickUpdatePDPA}>{config.language === 'en' ? 'Accept' : 'ยอมรับ' }</button>
                     <button className="text-white bg-gray-300 rounded-full px-4 py-1">{config.language === 'en' ? 'Cancel' : 'ไม่ยอมรับ' }</button>
                  </div>
               </div>            
            }            
         </div>            
      </div>
   )
}

const fetchUpdateAgreement = async (agreement, profile, setUpdate) => {
   if(profile) {
      let response = await fetch(`/mtscapital/line/liff/user/agreement`, {
         method: 'post',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({ userId: profile?.userId, agreement })
      }) 
      setUpdate(new Date())     
   }
}

const LineProfile = ({update, setShowModalAgreement}) => {
   const [profile] = useContext(Profile)

   useEffect(() => {
      if(profile) { fetchUserData(profile.userId, setShowModalAgreement) }
   }, [update, profile, setShowModalAgreement])

   return(
      <div></div>
   )
}

const fetchUserData = async (userId, setShowModalAgreement) => {
   let response = await fetch(`/mtscapital/line/liff/user/${userId}`) 
   let user = await response.json()
   console.log(user);
   setShowModalAgreement(user.redeem?.term && user.redeem?.pdpa)
}