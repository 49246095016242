import { useEffect, useState } from "react"




const Users = () => {
   const [userId, setUserId] = useState('')

   return (
      <div className="flex ">
         <UserList userId={userId} setUserId={setUserId} />
         <UserProfile userId={userId} />
         <UserChat userId={userId} />
      </div>
   )
}


export default Users


const UserList = ({ userId, setUserId }) => {
   const [users, setUsers] = useState([])
   useEffect(() => { 
      fetch('/mtsplus/app/users')
      .then((response) => response.json())
      .then((result) => setUsers(result))
   }, [])

   const [filter, setFilter] = useState({ search: '' })
   const handleChangeFilterSearch = (event) => { setFilter({...filter, search: event.target.value})}

   return (
      <div className="p-2">
         <div className="font-bold">User List</div>
         <input className="border px-2 py-1 border my-2" placeholder="Search" type="text" value={filter.search} onChange={handleChangeFilterSearch}/>
         <div className="grid">
            { users.filter((user) => user.line?.profile?.displayName.toLowerCase().includes(filter.search.toLowerCase()))
               // .sort((a, b) => a.)
               .map((user) => <UserItem key={user.line?.profile?.userId} user={user} userId={userId} setUserId={setUserId}/>)}
         </div>
      </div>
   )
}

const UserItem = ({ user, userId, setUserId }) => {
   const handleClickUser = () => { setUserId(user.line?.profile?.userId) }

   return (
      <button className="border py-1 px-2 flex gap-1 items-center disabled:bg-blue-100" 
         disabled={userId === user.line?.profile?.userId} onClick={handleClickUser}>
         <img className="w-8 rounded-full" src={user.line?.profile?.pictureUrl} alt="profile"/>
         <p className="text-left w-36 truncate">{user.line?.profile?.displayName}</p>
      </button>
   )
}


const UserProfile = ({ userId }) => { 
   const [user, setUser] = useState({})
   useEffect(() => { 
      userId && fetch(`/mtsplus/app/user/${userId}`)
         .then((res) => res.json())
         .then((data) => { setUser(data) })   
   }, [userId])

   return (
      <div className="border flex-1 w-full">
         <p>User Profile</p>
         
         <div className="flex gap-1 p-2">
            <img className="w-12 rounded-full" src={user?.line?.profile?.pictureUrl} alt="profile"/>
            <div>
               <div>UserId: {user?.line?.profile?.userId}</div>
               <div>DisplayName: <span>{user?.line?.profile?.displayName}</span></div>
            </div>
         </div>
         
      </div>
   )
}


const UserChat = ({ userId }) => {
   const [update, setUpdate] = useState(new Date())
   const [messages, setMessages] = useState([])
   useEffect(() => { 
      fetch(`/mtsplus/app/chat/message/${userId}`)
      .then((response) => response.json())
      .then((data) => { setMessages(data) })
   }, [update, userId])


   const [message, setMessage] = useState('')
   const handleChangeMessage = (event) => { setMessage(event.target.value); }
   const handleClickSend = async () => { 
      message && fetch(`/mtsplus/app/chat/message/sendto/${userId}`, { 
            method: 'post', 
            headers: { 'Content-Type': 'application/json' }, 
            body: JSON.stringify({ text: message }) 
         }).then(() => { setMessage(''); setUpdate(new Date()); })         
   }
   return (
      <div className="p-2"> 
         <p>Chat</p>
         <div className="border h-40 w-64 my-1 flex flex-col gap-1">
            { messages.map((message) => <ChatMessage message={message} userId={userId}/>)} 
         </div>
         <div className="flex gap-1">
            <input className="border flex-1 text-sm px-2" value={message} onChange={handleChangeMessage} />
            <button className="bg-orange-600 text-white px-2 " onClick={handleClickSend}>Send</button>
         </div>
         
      </div>
   )
}

const ChatMessage = ({ message, userId }) => {
   const { source } = message
   const datetime = new Date(message.datetime).toLocaleTimeString('th-TH', { hour: 'numeric', minute: '2-digit' })
   return (
      <div className="flex px-1">
         { source === userId && <div className="mr-auto flex gap-1 items-baseline">
            <p className="text-sm border rounded-full px-2 ">{message.text}</p>
            <p className="text-[10px] ">{datetime}</p>
         </div> }
         { source === 'mtsplus' && <div className="ml-auto flex gap-1 items-baseline">
            <p className="text-[10px]">{datetime}</p>
            <p className="text-sm border rounded-full px-2">{message.text}</p>
         </div> }
      </div>
   )
}