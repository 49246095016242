/* eslint-disable no-undef */
import { useEffect } from "react";
//import IminPrinter from './imin-printer.esm.browser.min.js';

const Test = () => {
   useEffect(() => {  
      const script = document.createElement('script');
      script.src = "/test/imin-printer.min.js";
      script.async = true;
      document.body.appendChild(script);
      return () => { document.body.removeChild(script); }
   }, [])


   const handleClickPrint = () => { 
      const IminPrintInstance = new IminPrinter();
      IminPrintInstance.connect().then(async (isConnect) => {

         if (isConnect) { 
            IminPrintInstance.initPrinter();
            const status = await IminPrintInstance.getPrinterStatus();
            console.log('Printer status:' + JSON.stringify(status));

            IminPrintInstance.setAlignment(0);
            IminPrintInstance.setTextSize(32);
            IminPrintInstance.printText(`MTS Gold สวัสดีครับ`)

            IminPrintInstance.setAlignment(1);
            IminPrintInstance.setTextSize(128);
            IminPrintInstance.setTextTypeface(3);
            IminPrintInstance.setTextStyle(1);
            IminPrintInstance.printText(`A001`)

            IminPrintInstance.printAndFeedPaper(162);
         }
      })
   //   const content = document.getElementById("content");
   //   content.print();
   }
 
   return (
     <div className="text-6xl">
       <div id="content">Test A001</div>
       <button className="bg-gray-200 px-4 py-1" onClick={handleClickPrint}>Print</button>
     </div>
   )
}
export default Test


//  // Instantiate the print plugin
//  var IminPrintInstance = new IminPrinter();
//  //Create a webosket connection
//  IminPrintInstance.connect().then(async (isConnect) => {
//  // The websocket status is linked
//  if (isConnect) {
//     IminPrintInstance.initPrinter();
//     const status = await IminPrintInstance.getPrinterStatus();
//     console.log('Printer status:' + JSON.stringify(status));

//     IminPrintInstance.printText('iMin advocates the core values of "Integrity, Customer First, Invention&Creation, Patience"')


//  }
//  // Note: All encapsulated print methods must be called in the context of a websocket link
//  })