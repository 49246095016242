import { useEffect, useRef, useState } from 'react'
//import './style.css';

function Broadcast({channel}) {
  const [template, setTemplate] = useState('highlight')


  return (
    <div className="container mx-auto grid grid-cols-3 lg:grid-cols-5 py-3">
      <div className="col-span-1">
        <BroadcastList template={template} setTemplate={setTemplate}/>
      </div>
      <div className="col-span-2 lg:col-span-4">
        <div className="px-3">
          { template === 'highlight' && <Template1 channel={channel} />}
          { template === '2' && <Template2 channel={channel} />}
          { template === 'news' && <BroadcastNews channel={channel}/>}
          { template === 'promotion' && <div><h5>Promotion</h5> </div>}
        </div>
      </div>
    </div>

  )
}

const BroadcastList = ({template, setTemplate}) => {
  const list = [
    { text: 'MTS Highlight', icon: 'feed', value: 'highlight', template, setTemplate },
    { text: 'Template-2', icon: 'newspaper', value: 'template-2', template, setTemplate },
    { text: 'News', icon: 'newspaper', value: 'news', template, setTemplate },
    { text: 'Promotion', icon: 'sell', value: 'promotion', template, setTemplate },
    { text: 'Announcement', icon: 'history_edu', value: 'announce', template, setTemplate },
  ]
  return (
    <div className="grid gap-2">
      <div className="flex items-center font-bold gap-1 px-2 py-1">
        <i className="material-icons-outlined">podcasts</i> 
        <div>Broadcast</div>
      </div>
      <div className="">
        { list.map((item) => <BroadcastItem {...item}/>)}
      </div>

    </div>
  )
}

const BroadcastItem = ({text, icon, value, template, setTemplate}) => {
  return (
    <div className={`flex items-center gap-1 px-6 py-1 border cursor-pointer ${value===template?'bg-blue-100':''}`}
      onClick={() => { setTemplate(value) }}>
      <i className="material-icons-outlined text-lg">{icon}</i> 
      <div className="text-sm">{text}</div>
    </div>
  )
}





const Template1 = ({channel}) => {
  const inputImageRef = useRef()
  const [files, setFiles] = useState(null)
  const [link1, setLink1] = useState('')
  const [link2, setLink2] = useState('')
  const [link3, setLink3] = useState('')
  const [link4, setLink4] = useState('')

  const [result, setResult] = useState(null)

  function handleSubmit(event) {
    event.preventDefault()    
    console.log(event);
    if(window.confirm(`ยันยัน Broadcast Template 1 ไปยัง channel : ${channel}`)) {
      fetch(`/mtsgold/app/line/braodcast/template-1/${channel}`, {
        method: 'POST',
        body: new FormData(event.target)
      })
      .then((response) => response.json())
      .then((json) => { setResult(json) })
      .catch((error) => { console.log(error) })      
    }
  }

  useEffect(() => {
    console.log(files);
    inputImageRef.current.files = files
  }, [files])
  
  return (
    <div className="">
      <div className="h5 my-2"> Template 1 - MTS HIGHLIGHT</div>
      <form className="grid grid-cols-3 gap-2 " onSubmit={(event) => { handleSubmit(event) }} >
        <div className="">
          <div className=""
            onDragOver={(event) => { 
              event.preventDefault()
              event.target.classList.toggle(
                ['image/png', 'image/jpeg'].includes(event.dataTransfer.items[0].type) ? "bg-gray-100" : "bg-red-100", true)   
            }}
            onDragLeave={(event) => { 
              event.preventDefault()
              event.target.classList.toggle("bg-gray-100", false)
              event.target.classList.toggle("bg-red-100", false)
            }}
            onDrop={(event) => { 
              event.preventDefault()
              if(event.dataTransfer.items[0].kind === 'string') return      
              setFiles(event.dataTransfer.files)
            }} >
            { files 
                ? <div className="grid gap-1"> 
                    <img className="border p-1" alt="highlight" width="100%" src={files && URL.createObjectURL(files[0])} /> 
                    <button className="bg-red-400 px-2 py-1 text-white flex gap-1 justify-center"
                      onClick={() => { setFiles(null) }}>
                      <i className="material-icons-outlined text-lg">cancel</i>
                      <span className="self-center">Cancel Upload File</span>
                    </button>
                  </div>     
                : <div className="border grid items-center text-center h-48">
                    <div> 
                      <div>Drop Image file for upload..  </div>
                      <label htmlFor="highlight-image">
                        or Click to <span className="underline cursor-pointer">Choose File...</span>
                      </label>
                    </div>
                  </div>
            }            
          </div>   
          <input id="highlight-image" className="hidden" type="file" name="image" ref={inputImageRef}
            accept=".png,.jpg,.jpeg" onChange={(event) => { setFiles(event.target.files) }} />  
        </div>

        <div className="grid gap-2 col-span-2 auto-rows-min">
          <label>Link News 1: </label>
          <input className="border px-2 py-1"
            type="url" name="link" placeholder="https://"
            value={link1} onChange={(event) => { setLink1(event.target.value) }} />  
          <label>Link News 2: </label>
          <input className="border px-2 py-1"
            type="url" name="link" placeholder="https://"
            value={link2} onChange={(event) => { setLink2(event.target.value) }} />  
          <label>Link Strategy: </label>
          <input className="border px-2 py-1"
            type="url" name="link" placeholder="https://"
            value={link3} onChange={(event) => { setLink3(event.target.value) }} />  
          <label>Link Ad 1: </label>
          <input className="border px-2 py-1"
            type="url" name="link" placeholder="https://"
            value={link4} onChange={(event) => { setLink4(event.target.value) }} />  

          <div className="flex">
            <button className="bg-blue-500 px-2 py-1 text-white flex gap-1" type="submit">
              <i class="material-icons-outlined text-lg">send</i> <span className="self-center">Send Broadcast</span>
            </button>
            { result && 
              <div className={`flex-grow ${(result.status === 'ok')?'alert-success':'alert-danger'} my-2 p-2 text-center fw-bold`}> 
                {result.message} 
              </div>        
            }
          </div>
        </div>
      </form>
    </div>
  )
}

const Template2 = ({channel}) => {
  return (
    <div className="container">
      <div className="h5 my-2"> Template 2</div>
    </div>
  )
}



function BroadcastNews({channel}) {
  const [date, setDate] = useState('')
  const [result, setResult] = useState({})
  const [inputNews, setInputNews] = useState([0,1,2])
  
  function handleSubmit(event) {
    event.preventDefault()
    console.log(event);
    if(window.confirm(`ยันยัน Broadcast News ไปยัง channel : ${channel}`)) {
      fetch(`/mtsgold/line/broadcast/news?channel=${channel}`, {
        method: 'POST',
        body: new FormData(event.target)
      })
      .then((response) => response.json())
      .then((json) => { setResult(json) })
      .catch((error) => { console.log(error) })      
    }
  }

  return (
    <div className="">     
      <form className="d-grid gap-2 px-3" onSubmit={(event) => { handleSubmit(event) }} >
        <div className="d-flex my-2">
          <h5>News</h5>
          <div className="input-group input-group-sm ms-auto" style={{width: '250px'}}>
            <div className="input-group-text">
              Date Input
            </div>
            <input className="form-control form-control-sm me-2"
              type="text" name="date" value={date} 
              onChange={(event) => { setDate(event.target.value) }} />      
          </div>
          <button className="btn btn-sm btn-success disabled" type="button"
            onClick={() => { setInputNews(prev => [...prev,prev.length]) }} >
            <i className="fas fa-plus-square"></i> Add News
          </button> 
        </div>    
        { inputNews.map((n, i) => <InputNewsData number={i+1}/>) }   
        <button className="btn btn-primary pointer my-2" type="submit">Send Broadcast</button>
      </form>
      { result?.status === 'ok' && 
        <div className="alert alert-success mx-3" role="alert"> {result?.message} </div>        
      }
      { result?.status === 'error' && 
        <div className="alert alert-danger mx-3" role="alert"> {result?.message} </div>        
      }
    </div>
  )
}

function InputNewsData({number}) {
  const [file, setFile] = useState(null)
  const [text, setText] = useState('')
  const [textColor, setTextColor] = useState('')
  const [link, setLink] = useState('')
  const [linkColor, setLinkColor] = useState('')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const link_regex = /^https?:\/\/(www\.)?[-+a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,8}\b([-a-zA-Z0-9()!@:%_+.~#?&/=]*)/gm


  useEffect(() => { 
    if(text.length > 200) setTextColor('border-danger')
    else if(text.length > 150) setTextColor('border-warning')
    else setTextColor('')
  }, [text])
  useEffect(() => { 
    if(link_regex.test(link)) setLinkColor('')
    else setLinkColor('border-danger')
  }, [link, link_regex])


  return(
    <div className="px-3 py-2 border">
      <div className="row g-3">
        <div className="col-4 d-flex align-items-start flex-column">
          <div className="border border-4 rounded-2" height="120px"
            onDragOver={(event) => { }}
            onDrop={(event) => { }} >
            <div>Drap Image File.. </div>
            { file && <img className="my-2" alt={`news_image_${number}`} width="100%" src={URL.createObjectURL(file)} /> }            
          </div>
          <label className="mt-auto">Upload Image : </label>
          <input className="form-control form-control-sm" type="file" name="image"
            accept=".png,.jpg,.jpeg" 
            onChange={(event) => { setFile(event.target.files[0]) }} 
          />            
        </div>        
        <div className="col-8">
          <label>Title :</label>
          <input className="form-control form-control-sm fw-bold" type="text" name="title" />            
          <label>Text : </label>
          <textarea className={`form-control ${textColor}`} name="text"
            value={text} onChange={(event) => { setText(event.target.value) }}
          />
          <label>Link : </label>
          <input className={`form-control form-control-sm ${linkColor}`}
            type="url" name="link" placeholder="https://"
            value={link} onChange={(event) => { setLink(event.target.value) }}
          />  
        </div>
      </div>
    </div>
  )
}

export default Broadcast