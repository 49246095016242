import { useState, useEffect, useRef,  } from "react"
import { v4 as uuidv4 } from 'uuid';

import TemplateUploadPoint from './Files/TemplateUploadPoint.csv'

const Point = () => {

   return (
      <div className="grid gap-2">
         <UploadPoint />
         <RecentDataPoint />
      </div>
   )
}

export default Point

const UploadPoint = () => {
   const uploadRef = useRef(null)
   const [tableData, setTableData] = useState({ headers: [], rows: [] })

   const handleChangeFile = (event) => {
      const reader = new FileReader();
      reader.readAsText(event.target.files[0])
      reader.addEventListener("load", () => {
         let str = reader.result
         const headers = str.slice(0, str.indexOf("\r\n")).split(',');
         const rows = str.slice(str.indexOf("\r\n") + 2).split("\r\n");
         setTableData({ headers, rows })
      })
   }

   const handleClickUpload = () => {      
      fetch('/mtscapital/app/redeem/point/upload', { 
         method: 'post', 
         body: new FormData(uploadRef.current)
      }).then((response) => response.json())
      .then((data) => { setTableData({ headers: [], rows: [] }) })
      .catch(() => { console.log('Upload Point Error') })
   }

   return (
      <div className="p-4 border grid gap-2">
         <div className="flex justify-between items-baseline">
            <div className="text-xl font-bold">Upload Point File </div>   
            <div className="flex gap-2 text-sm">
               <label>Template File </label> 
               <a className="underline" href={TemplateUploadPoint} download> 
                  <i className="material-icons text-xs">description</i>
               </a>
            </div>                    
         </div>

         <form ref={uploadRef} className="flex gap-2" onSubmit={(event) => { event.preventDefault() }}>
            <input className="border text-sm w-full" type="file" name="csvFile" accept=".csv" onChange={handleChangeFile}/>
            <button className="p-1 bg-blue-200 text-sm w-20" onClick={handleClickUpload}>Upload</button>            
         </form>

         <table className="border-collapse border">
            <TableRowHeaderItem value={tableData?.headers} />
            { tableData?.rows.map((row) => <TableRowDataItem row={row} />)}
         </table>         
      </div>      
   )
}

const TableRowHeaderItem = ({ value }) => {
   return (
      <tr className="border text-xs text-left">
         { value.map(val => <th className="border px-2">{val}</th>)}
      </tr>
   )
}

const TableRowDataItem = ({ row }) => {
   let value = row.split(',');
   return (
      <tr className="border text-xs text-right">
         { value.map(val => <td className="border px-2">{val}</td>)}
      </tr>
   )
}

const RecentDataPoint = () => {
   const [showDataNumber, setShowDataNumber] = useState(10);
   const [update, setUpdate] = useState([])
   const [pointData, setPointData] = useState([])
   const [pageNumber, setPageNumber] = useState(0)

   useEffect(() => { 
      fetch('/mtscapital/app/redeem/transaction/point')
      .then((response) => response.json())
      .then((data) => { setPointData(data.transactions) })
   }, [update])

   const handleClickRefresh = () => { setUpdate([]) }


   const pointPageData = []
   for (let i = 0; i < Math.floor(pointData.length/showDataNumber)+1; i++) {
      pointPageData.push(pointData.slice(i*showDataNumber, (i+1)*showDataNumber))
   }

   const handleClickPaginationButton = (event) => { setPageNumber(Number(event.target.value)) }
   const handleClickNextPageinationButton = () => { setPageNumber(pageNumber+1 === pointPageData.length ? pageNumber : pageNumber+1) }
   const handleClickPreviousPageinationButton = () => { setPageNumber(pageNumber-1 < 0 ? 0 : pageNumber-1) }
   const handleChangeShowDataNumberSelect = (event) => { setShowDataNumber(Number(event.target.value)|| 1); setPageNumber(0); }

   return (
      <div className="border p-4 grid gap-4">
         <div className="flex justify-between items-center">
            <div className="font-bold text-xl">Point Data</div>
            <button className="px-2 py-1 border text-sm" onClick={handleClickRefresh}> 
               Refresh <i className="material-icons text-sm">refresh</i>
            </button>
         </div>
         
         <div className="grid gap-1 text-sm">
            <RecentPointItem item={{datetime: 'Datetime', account: 'Account',title: 'Title', detail: 'Detail', value: 'Point'}}/>
            <div className="border py-1 overflow-y-auto" style={{ height: (28*showDataNumber)+10, maxHeight: '580px' }}>
               { pointPageData[pageNumber].map((item) => <RecentPointItem item={item}/>)}
            </div>
            <div className="flex items-center gap-2 justify-end">
               <div>
               <button className="py-1 px-3 border" onClick={handleClickPreviousPageinationButton}>{'<'}</button>   
                  { pointPageData.map((data, i) => 
                     <button key={uuidv4()} className={`py-1 px-3 border ${pageNumber === i ? 'bg-gray-100' : ' '}`}
                        value={i} onClick={handleClickPaginationButton}>{i+1}
                     </button>) 
                  } 
                  <button className="py-1 px-3 border" onClick={handleClickNextPageinationButton}>{'>'}</button>                 
               </div>               
               <div className="border px-2 py-1">show 
                  <select className="" onChange={handleChangeShowDataNumberSelect}>
                     <option value="10">10</option>
                     <option value="20">20</option>
                     <option value="50">50</option>
                     <option value="100">100</option>
                  </select>  
               </div>
            </div>
         </div>
      </div>
   )
}


const RecentPointItem = ({item}) => {
   return (
      <div className="py-1 flex text-center">
         <div className="w-20 lg:w-28">{new Date(item.datetime).toLocaleDateString() || item.datetime}</div>
         <div className="w-24 lg:w-32">{item.account}</div>
         <div className="flex-1 truncate">{item.title}</div>
         <div className="flex-1 truncate">{item.detail}</div>
         <div className="w-24 lg:w-36">{item.value}</div>
      </div>      
   )
}