import { useState, useEffect, useRef } from "react"


const Items = () => {
   const [items, setItems] = useState([])
   const [update, setUpdate] = useState(new Date())
   const [newItem, setNewItem] = useState(false)

   useEffect(() => { 
      fetch('/mtscapital/app/redeem/items')
      .then((response) => response.json())
      .then((data) => { setItems(data.items) })
      .catch(() => { console.log('Error Get Redeem Items') })
   }, [update])

   const handleClickNewItemButton = () => { setNewItem(!newItem) }

   return(
      <div className="border p-4 grid gap-2">
         <div className="flex justify-between">
            <div className="font-bold text-xl">Redeem Item</div>
         </div>
         <div className="grid gap-2 lg:grid-cols-2 xl:grid-cols-3 w-full">
            { items.map((item) => <RedeemItem key={'redeem-item-'+item.id} item={item} setNewItem={setNewItem} setUpdate={setUpdate}/>)}
            { newItem
               ?  <RedeemItem key={'redeem-item-new'} item={{}} newItem={newItem} setNewItem={setNewItem} setUpdate={setUpdate}/>
               :  <button className="p-2 border bg-blue-100 flex items-center justify-center gap-1"
                     onClick={handleClickNewItemButton}>
                     <i className="material-icons text-base">add_circle</i> <div>New Item</div> 
                  </button>
            }
         </div>
      </div>
   )
}

export default Items

const RedeemItem = ({ item, newItem, setNewItem, setUpdate }) => {
   const formRef = useRef(null)
   const InputRef = useRef(null)

   const [editable, setEditable] = useState(newItem)
   const [inputData, setInputData] = useState({ pictureUrl: item.pictureUrl, name: item.name, value: item.value, total: item.total, file: null })

   const handleClickEditable = () => { setEditable(true) }
   const handleClickPublish = () => { fetchRedeemItemPublish({ itemId: item.id, publish: !item.publish, setUpdate }) }
   const handleClickSave = (event) => { 
      fetch('/mtscapital/app/redeem/item/update', { 
         method: 'POST',
         body: new FormData(formRef.current)
      })
      .then((response) => response.json())
      .then((data) => { setUpdate([]) })
      .catch(() => { console.log('Update Data Error') })
      setEditable(false) 
      setNewItem(false) 
      formRef.current.reset()
   }
   const handleClickDelete = (event) => {
      fetch('/mtscapital/app/redeem/item/delete', { 
         method: 'post',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({ itemId: item.id })
      })
      .then((response) => response.json())
      .then((data) => { setUpdate([]) })
      setNewItem(false) 
   }

   const handleChangeNameInput = (event) => { setInputData((inputData) => ({...inputData, name: event.target.value })) }
   const handleChangeValueInput = (event) => { setInputData((inputData) => ({...inputData, value: event.target.value })) }
   const handleChangeTotalInput = (event) => { setInputData((inputData) => ({...inputData, total: event.target.value })) }

   const handleClickFileInput = () => { editable && InputRef.current.click() } 
   const handleChangeFileInput = (event) => { setInputData((inputData) => ({...inputData, pictureUrl: URL.createObjectURL(event.target.files[0]) })) } 

   return (
      <form ref={formRef} className="border p-2 flex gap-2" onSubmit={(event) => { event.preventDefault() }}>
         <div className={`relative w-32 border ${editable ? 'cursor-pointer' : ''}`} onClick={handleClickFileInput}>
            <img className="" src={inputData.pictureUrl} alt="item" />
            <input ref={InputRef} className="hidden" type="file" name="imageFile" onChange={handleChangeFileInput} />
            { editable && <div className="absolute bottom-0 text-xs bg-blue-200/40 w-full text-center">upload file</div> }
            <div className="absolute top-0">
               { item.publish
                  ? <button className="material-icons text-lg text-green-600 px-1" onClick={handleClickPublish}>check_circle</button> 
                  : <button className="material-icons text-lg text-gray-600 px-1" onClick={handleClickPublish}>unpublished</button>
               }               
            </div>

         </div>
         <div className="grid gap-1 w-full auto-rows-min">
            <div className="flex items-baseline gap-2">
               <label className="w-14 text-sm">ItemId.</label>
               { !newItem && <div className="text-base flex-1">{item.id}</div> }
               <input type="text" className={`border flex-1 w-full px-1 ${newItem ? '': 'hidden'}`} name="id" value={item.id} readOnly={!newItem} />
               { editable
                  ?  <div className="flex gap-2">
                        <button className="material-icons text-base text-red-600" onDoubleClick={handleClickDelete}>delete</button>
                        <button className="material-icons text-base text-blue-600" onClick={handleClickSave}>save</button>
                     </div>
                  :  <button className="material-icons text-base " onClick={handleClickEditable}>edit</button>

                   
               }
               
            </div>
            <div className="flex gap-2 text-sm"> 
               <label className="w-14">Name</label> 
               { editable 
                  ?  <input type="text" className="border flex-1 px-1" 
                        name="name" value={inputData.name} onChange={handleChangeNameInput} /> 
                  :  item.name 
               } 
            </div>
            <div className="flex gap-2 text-sm"> 
               <label className="w-14">Value</label> 
               { editable 
                  ?  <input type="text" className="border flex-1 px-1" 
                        name="value" value={inputData.value} onChange={handleChangeValueInput} /> 
                  :  item.value 
               } 
            </div>   
            <div className="flex gap-2 text-sm"> 
               <label className="w-14">Total</label> 
               <div className="px-1">{`${item.used} /`}</div>
               { editable 
                  ?  <input type="text" className="border flex-1 w-full" 
                        name="total" value={inputData.total} onChange={handleChangeTotalInput} /> 
                  :  item.total
               } 
            </div>                 
         </div> 
      </form>
   )
}

const fetchRedeemItemPublish = ({ itemId, publish, setUpdate }) => {
   fetch(`/mtscapital/app/redeem/item/publish`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ itemId, publish })
   })
   .then(() => { setUpdate(new Date()) })
}

