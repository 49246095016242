import Home from "./Home"
import History from "./History"
import Redeem from "./Redeem"
import { useContext } from "react"
import { Config } from "../../Context"


const Body = ({profile, language, page, setPage}) => { 
   const [config] = useContext(Config)

   return (
      <div className="">
         { config.page === 'home' && <Home setPage={setPage} />}
         { config.page === 'history' && <History language={language} profile={profile} setPage={setPage} />}
         { config.page === 'redeem' && <Redeem language={language} profile={profile} setPage={setPage} />}
      </div>
   )
}

export default Body
