import { useState, useEffect, useContext } from "react"
import { Profile } from "../../Context"
import AccountList from "./AccountList";
import AccountModal from "./AccountModal";

import liff from '@line/liff';

const admin_profile = {
   "userId":"U7654ec16268f7599f47785afb8693405",
   "displayName":"Pipob Piya : 方坤",
   "pictureUrl":"https://sprofile.line-scdn.net/0hgNU8oE9oOGF4QS6-lbJGHggROwtbMGFzBiN1VB8VYAYSJXc_VyJxUEwRNQEVeXk2Vy90BktENlF0Uk8HZhfEVX9xZlZBdn8xXCF_gQ",
   "statusMessage":"If you nothing one, there is a man to beside.",
   "language":"th"
}

const LineProfile = ({ setPage }) => {
   const [profile, setProfile] = useContext(Profile)

   useEffect(() => {
      try {
         liff.init({ liffId: "1656990610-DGAqLvgb" }) //MTS GOLD LOGIN
         .then(() => { 
            if (liff.isLoggedIn()) { 
               console.log(liff.getAccessToken());
               liff.getProfile().then((data) => { setProfile(data) })
            } else {
               if (window.location.search === '?mode=admin') setProfile(admin_profile)
               else liff.login()
            }
         })      
      } 
      catch { console.log('Liff Init Error') }
   }, [setProfile])


   const [showAccountList, setShowAccountList] = useState(false)
   const [showAccountModal, setShowAccountModal] = useState(false)

   const handleClickProfile = () => { setShowAccountList(prev => !prev) }
   return (
      <div className="relative">
         <button className="flex gap-2 items-center  p-1 pl-2" onClick={handleClickProfile}>
            <div className="text-white">{profile?.displayName}</div>
            <img className="rounded-full border-2 border-gray-400 w-8" src={profile?.pictureUrl} alt="profile"/>
         </button>  
         { showAccountList && <AccountList setShowAccountList={setShowAccountList} setShowAccountModal={setShowAccountModal}/> } 
         { showAccountModal && <AccountModal setShowAccountModal={setShowAccountModal}/> }      
      </div>
   )
}




export default LineProfile


