import { useState, useEffect, useContext } from "react"

import { Profile, Config, Redeem } from "../../Context"

// import HistoryButton from "./Images/HistoryButton.svg"
// import RedeemButton from "./Images/RedeemButton.svg"

const Home = () => {
   const [profile] = useContext(Profile)
   const [config, setConfig] = useContext(Config)
   const [redeem, setRedeem] = useContext(Redeem)
   const [update, setUpdate] = useState(new Date())
   const [loading, setLoading] = useState(false)
   const [statusExpand, setStatusExpand] = useState(false)

   useEffect(() => {
      setLoading(true) 
      fetch(`/mtscapital/line/liff/point/user/${profile?.userId}`)
      .then((response) => response.json())
      .then((data) => { 
         setRedeem((redeem) => ({...redeem, userPoint: data.point})) 
         setLoading(false) 
      })
      .catch(() => { console.log('Get Point Error') })
   }, [profile, setRedeem, update])

   const handleClickLoading = () => { setUpdate(new Date()) }
   const handleClickToHistoryPage = () => { setConfig({...config, page: 'history'}) }
   const handleClickToRedeemPage = () => { setConfig({...config, page: 'redeem'}) }
   const handleClickStatusExpand = () => { setStatusExpand((statusExpand) => false) }


   return (
      <div className="flex justify-center items-center h-screen">
         <div className="">
            <div className="bg-blue-900/60 rounded-full w-64 h-64 mx-auto flex items-center">
               <button className="bg-white rounded-full w-56 h-56 flex justify-center items-center mx-auto"
                  onClick={handleClickLoading}>
                  { loading 
                     ?  <div>Loading ... </div>
                     :  <div className="text-center pt-6">
                           <div className="text-6xl">{Number(redeem.userPoint).toLocaleString()}</div>
                           <div className="text-2xl">{config.language === 'en' ? 'Point' : 'คะแนน' }</div>  
                           <div className="text-sm text-gray-400 flex justify-center items-center gap-1 " >
                              <i className="material-icons">refresh</i> 
                              {config.language === 'en' ? 'Refresh' : '' }
                           </div>
                           
                        </div>
                  }
                     

               </button>
            </div>
               
            
            <div className="flex justify-center gap-6 my-4  font-semibold">

               {/* <img src={HistoryButton} className="w-36 cursor-pointer" alt="history" onClick={handleClickToHistoryPage} />
               <img src={RedeemButton} className="w-36 cursor-pointer" alt="redeem" onClick={handleClickToRedeemPage} /> */}

               <button className="flex gap-1 bg-blue-900 text-white py-2 px-4 rounded-full" 
                  onClick={handleClickToHistoryPage}>
                  <i className="material-icons">history</i> 
                  { config.language === 'en' ? 'History' : 'ประวัติรายการ' } 
               </button>
               <button className="flex gap-1 bg-yellow-500 text-black py-2 px-4 rounded-full" 
                  onClick={handleClickToRedeemPage}>
                  <i className="material-icons">redeem</i> 
                  { config.language === 'en' ? 'Redeem' : 'แลกคะแนน' }
               </button>
            </div> 
            <div className="h-32">
               <div className="w-full rounded-xl bg-white">
                  <button className="flex justify-between w-full items-center px-2" 
                     onClick={handleClickStatusExpand} disabled={true} >
                     <div className="flex gap-1 ">
                        <i className="material-icons">task_alt</i>
                        { config.language === 'en' ? 'Redeem Status' : 'สถานะการแลกคะแนน' }
                     </div>
                     <div className="p-1 h-8">
                        { statusExpand 
                           ? <i className="material-icons">expand_less</i>
                           : <i className="material-icons">expand_more</i>
                        }                     
                     </div>
                  </button>
                  { statusExpand &&   
                     <div className="p-1 text-sm">
                        <div>Reddem Item 1</div>
                        <div>Reddem Item 2</div>
                        <div>More..</div>
                     </div>  
                  }       
               </div> 
            </div>
     
         </div>
        
         

         {/* 
            

               
            </div>  

         </div>*/}
         {/* <div className="relative mx-auto flex items-center bg-gray-100 rounded-full w-60 h-60 cursor-pointer ">
            <div className="mx-auto text-center">
               { loading 
                  ?  <div className=""> Refresh.... </div>
                  :  <div>
                        
                                           
                     </div>              
               }
            </div>
            
            <div className="absolute bottom-3 right-3 rounded-full bg-gray-200 p-1 w-8 h-8" 
               onClick={handleClickLoading}>
               <i className="material-icons">refresh</i>
            </div> 
         </div> */}
         

      </div>
   )
}

export default Home