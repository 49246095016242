import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom'

// import Navigator from './Pages/Navigator';
import Test from './Pages/Test';
import Login from './Pages/Login';
import Invite from './Pages/MTSPlus/Invite';
import MTSPlus from './Pages/MTSPlus';
import MTSGold from './Pages/MTSGold';
import Datafeed from './Pages/Datafeed';
import Datafeed2 from './Pages/Datafeed/Datafeed2';
import Redeem from './Pages/MTSCapital/Liff-Redeem';
import MTSCapital from './Pages/MTSCapital/';


export default function App() {
  const [token, setToken] = useState('')

  useEffect(() => { 
    const intervalId = token && setInterval(() => { 
      fetch('/auth/app/test/token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token })
      })
      .then((response) => response.json())
      .then((data) => { !data?.result && setToken(null) })
      .catch((error) => setToken(null) ) 
    }, 30000)
    return () => clearInterval(intervalId)
  }, [token])
 
  return (
    <Switch>
      <Route path="/test"> <Test /> </Route>
      <Route path="/datafeed"> <Datafeed /> </Route>
      <Route path="/datafeed2"> <Datafeed2 /> </Route>
      <Route path="/mtsplus/invite"> <Invite /> </Route>
      <Route path="/mtscapital/redeem"> <Redeem /> </Route>

      
      <Route path="/mtsplus"> { (token)? <MTSPlus token={token} setToken={setToken}/> : <Login setToken={setToken} /> } </Route>
      <Route path="/mtsgold"> { (token)? <MTSGold token={token} setToken={setToken}/> : <Login setToken={setToken} /> } </Route>
      <Route path="/mtscapital"> { (token)? <MTSCapital token={token} setToken={setToken}/> : <Login setToken={setToken} /> } </Route>


    </Switch>
  )
}





