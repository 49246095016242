import { useState } from "react"

import Users from './Users'
import Items from './Items'
import Point from './Point'
import Transactions from './Transactions.jsx'
import Report from './Report.jsx'

const RedeemPage = ({login}) => {
   const [menu, setMenu] = useState('')

   return (
      <div className="grid sm:grid-cols-6 lg:grid-cols-8 xl:grid-cols-9 gap-4 p-4">
         <div className="sm:col-span-2">
            <MainMenu login={login} menu={menu} setMenu={setMenu} />
         </div>
         <div className="sm:col-span-4 lg:col-span-6 xl:col-span-7">
            { menu === 'Users' && <Users />}
            { menu === 'Items' && <Items /> } 
            { menu === 'Point' && <Point /> } 
            { menu === 'Transactions' && <Transactions /> } 
            { menu === 'Report' && <Report /> } 
         </div>
      </div>
   )
}
export default RedeemPage

const MainMenu = ({ login, menu, setMenu }) => {
   const menuAdmin = [
      { iconId: 'person', label: 'Users'},
      { iconId: 'redeem', label: 'Items'},
      { iconId: 'monetization_on', label: 'Point'},
      { iconId: 'shopping_cart_checkout', label: 'Transactions'},
      { iconId: 'content_paste', label: 'Report'},
   ]
   
   const menuFinance = [
      { iconId: 'person', label: 'Users'},
      { iconId: 'monetization_on', label: 'Point'},
      { iconId: 'shopping_cart_checkout', label: 'Transactions'},
   ]   

   const menuMarketing = [
      { iconId: 'person', label: 'Users'},
      { iconId: 'monetization_on', label: 'Point'},
      { iconId: 'shopping_cart_checkout', label: 'Transactions'},
   ] 

   const menuList = (login.role === 'admin') ? menuAdmin 
      : (login.role === 'finance') ? menuFinance
      : (login.role === 'marketing') ? menuMarketing
      : []
      // : (login.role === 'research') ? menuResearch
      // : []

   return (
      <div className="flex flex-wrap text-blue-900 sm:grid">
         { menuList.map(({iconId, label}) => <MenuItem iconId={iconId} label={label} menu={menu} setMenu={setMenu} />) }
      </div>
   )
}

const MenuItem = ({ iconId, label, menu, setMenu }) => {
   const display_class = 'flex justify-center sm:justify-start items-center gap-2'

   return (
      <div className="grid">
         <button className={`${display_class} px-3 py-2 border  ${menu === label ? 'bg-blue-100' : ''}`}
            onClick={() => { setMenu(label) }}>
            <i className="material-icons">{iconId}</i> {label}
         </button>         
      </div>

   )
}


const DropdownMenu = ({ menu, setMenu }) => {
   const [showMenu, setShowMenu] = useState(false)
   const handleClickSwitchMenu = (name) => { 
      setMenu(name)
      setShowMenu(!showMenu) 
   }

   return (
      <div className="sm:hidden" >
         { showMenu 
            ?  <div className="grid"> 
                  <button className={`${menu === 'Items' ? 'order-first': ''} px-2 py-1 border text-center`}
                     onClick={() => { handleClickSwitchMenu('Items') }}>Items</button>
                  <button className={`${menu === 'Point' ? 'order-first': ''} px-2 py-1 border text-center`}
                     onClick={() => { handleClickSwitchMenu('Point')  }}>Point</button>
                  <button className={`${menu === 'Transaction' ? 'order-first': ''} px-2 py-1 border text-center`}
                     onClick={() => { handleClickSwitchMenu('Transaction')  }}>Transaction</button>               
               </div>  
            :  <button className="w-full px-2 py-1 border text-center" onClick={() => { handleClickSwitchMenu(menu) }}>
                  { menu === 'Items' ? 'Items' : menu === 'Point' ? 'Point' : menu === 'Transaction' ? 'Transaction' : '' }
               </button>
         }
      </div>
   )
}

