import React, { useState, useEffect } from 'react'
import liff from '@line/liff';

import GoldAlert from './images/gold_alert.jpg'
//import SilverAlert from './images/silver_alert.jpg'
import FuturesAlert from './images/futures_alert.jpg'
//import CryptoAlert from './images/crypto_alert.jpg'

const packages = [
   { image: GoldAlert, name: 'Gold Alerts' },
   //{ image: SilverAlert, name: 'Silver Alerts' },
   { image: FuturesAlert, name: 'Futures Alerts' },
  // { image: CryptoAlert, name: 'Crypto Alerts' },
]


export default function Invite() {
   const [profile, setProflie] = useState()
   const [name, setName] = useState('')
   const [mobile, setMobile] = useState('')
   const [email, setEmail] = useState('')
   const [account, setAccount] = useState('')

   const [packageName, setPackageName] = useState()

   const [result, setResult] = useState({})
 
   async function submitRegister() {
      if(profile) {
         await fetch(`/mtsplus/app/user/${profile?.userId}/customer-register`, {
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
               profile, customer: { account, name, mobile, type: 'normal', package: packageName }
            })
         })  
         setResult({ color: 'text-green-400', text: 'Register Success' })     
      }
      else setResult({ color: 'text-red-400', text: 'Line Profile Error' })
   }


   return (
      <div className="grid gap-4 px-6 max-w-md mx-auto">
         <form className="grid gap-2">
            <div className="text-center text-xl">Register MTS Plus</div>
            <LineProfile profile={profile} setProflie={setProflie} />
            <RegisterInput icon="person" value={name} setValue={setName} placeholder="Name" required={true} />
            <RegisterInput icon="smartphone" value={mobile} setValue={setMobile} placeholder="Mobile Number" required={true} />
            <RegisterInput icon="email" value={email} setValue={setEmail} placeholder="Email" />
            <RegisterInput icon="description" value={account} setValue={setAccount} placeholder="Account Number" />
         </form>
         <div className="grid grid-cols-2 gap-2 px-6">
            { packages.map((item) => <PackageItem image={item.image} name={item.name} setPackageName={setPackageName}/> )}
         </div> 
         <div className="flex justify-center">
            <button className="py-2 px-4 rounded-lg bg-blue-700 text-white text-lg "
               onClick={() => { submitRegister() }}
            > ลงทะเบียน / Submit </button>            
         </div>
         <div className={`text-center ${result?.color}`}>{result?.text || ''}</div>
      </div>

   )
}

const LineProfile = ({profile, setProflie}) => {

   useEffect(() => {
      liff.init({ liffId: "1654184189-q82PraZj" }).then(() => {
         if (liff.isLoggedIn()) liff.getProfile().then((data) => { setProflie(data) })      
         //else liff.login()         
      }).catch((error) => { console.log(error); })
   }, [setProflie])

   return (
      <div className="flex gap-2 justify-center items-center">
         <img src={profile?.pictureUrl} alt="profile" className="w-12 border rounded-full" />
         <div className="text-lg"> {profile?.displayName} </div>
      </div>
   )
}

const RegisterInput = ({icon, placeholder, value, setValue, required}) => {
   return (
      <div className="flex ">
         <i className="material-icons-outlined rounded-l-md px-2 py-1 bg-gray-300">{icon}</i>
         <input type="text" className="flex-grow border rounded-r-md px-2 py-1" placeholder={placeholder} required={required}
            value={value} onChange={(event) => setValue(event.target.value) }/>
      </div>
   )
}


const PackageItem = ({image, name, setPackageName}) => {
   return (
      <div className="cursor-pointer ring-blue-300" onClick={() => { setPackageName(name) }}> 
         <img className="rounded-lg opacity-50 hover:opacity-75 active:opacity-100 w-48 h-24 object-cover"
            src={image} alt="package"/>
         <div className="text-center font-bold">{name}</div>
      </div>
   )
}