import { useContext } from "react"
import { Config } from "../../Context"

// import IconHistory from "./Images/IconHistory.svg"
// import IconHome from "./Images/IconHome.svg"
// import IconRedeem from "./Images/IconRedeem.svg"
// import IconTransation from "./Images/IconTransation.svg"


const Footer = () => {
   const [config, setConfig] = useContext(Config)

   const handleClickNavigatorHome = () => { setConfig((config) => ({...config, page: 'home'}))}
   const handleClickNavigatorRedeem = () => { setConfig((config) => ({...config, page: 'redeem'}))}
   const handleClickNavigatorHistory = () => { setConfig((config) => ({...config, page: 'history'}))}
   const handleClickNavigatorStatus = () => { setConfig((config) => ({...config, page: 'home'}))}

   return (
      <div className="">
         <div className="fixed bottom-0 w-full">
            <div className="flex justify-evenly bg-blue-900 items-center ">
               <i className={`material-icons ${config.page === 'history' ? 'border-b-4' : ''} p-2 cursor-pointer text-white text-3xl`} onClick={handleClickNavigatorHistory}>history</i>          
               <i className={`material-icons ${config.page === 'home' ? 'border-b-4' : ''} p-2 cursor-pointer text-white text-3xl`} onClick={handleClickNavigatorHome}>home</i>
               <i className={`material-icons ${config.page === 'redeem' ? 'border-b-4' : ''} p-2 cursor-pointer text-white text-3xl`} onClick={handleClickNavigatorRedeem}>redeem</i>
               {/* <i className={`material-icons ${config.page === 'status' ? 'border-b-4' : ''} p-2 cursor-pointer text-white text-3xl`} onClick={handleClickNavigatorStatus}>task_alt</i> */}
               {/* <img className={`${config.page === 'home'       ? 'w-16 h-16 border-b-4' : 'w-12 h-12'} cursor-pointer py-2`} src={IconHome} alt="footer" onClick={handleClickNavigatorHome}/>
               <img className={`${config.page === 'redeem'     ? 'w-16 h-16 border-b-4' : 'w-12 h-12'} cursor-pointer py-2`} src={IconRedeem} alt="footer" onClick={handleClickNavigatorRedeem}/>
               <img className={`${config.page === 'history'    ? 'w-16 h-16 border-b-4' : 'w-12 h-12'} cursor-pointer py-2`} src={IconHistory} alt="footer" onClick={handleClickNavigatorHistory}/>
               <img className={`${config.page === 'transation' ? 'w-16 h-16 border-b-4' : 'w-12 h-12'} cursor-pointer py-2`} src={IconTransation} alt="footer" onClick={handleClickNavigatorTransation}/> */}
            </div>             
         </div>
      </div>
   )
}

export default Footer